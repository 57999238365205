import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import managerReducer from '../features/scene/managerSlice';
import counterReducer from '../features/counter/counterSlice';
import cm6EditorReducer from '../features/cm6Connect/cM6EditorSlice';
import renderEditorReducer from '../features/cm6Connect/renderEditorSlice';
import uiReducer from '../features/app/uiSlice';
import pongReducer from '../features/scene/pong/pongSlice';
import userReducer from '../features/user/userSlice';
import apiReducer from '../api/apiSlice';
import authReducer from '../features/auth/authSlice';
import stripeReducer from '../features/app/stripe/stripeSlice';

import { codeMirrorMiddleWare } from '../features/cm6Connect/middleware/codeMirrorMiddleWare';

export const store = configureStore({
  reducer: {
    manager: managerReducer,
    counter: counterReducer,
    cm6Editor: cm6EditorReducer,
    renderEditor: renderEditorReducer,
    pong: pongReducer,
    brickBreaker: pongReducer,
    spaceInvaders: pongReducer,
    ui: uiReducer,
    user: userReducer,
    api: apiReducer,
    auth: authReducer,
    stripe: stripeReducer

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(codeMirrorMiddleWare)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
