import axios from "axios";

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../app/store';
import {API_EXPVI_URL, API_LEVEL_URL, AUTH0_DOMAIN_DEV, LAST_LEVEL} from "../constants/constants";
import {
    ApiCreateUserAccount,
    ApiLoadingState,
    ApiLoginCredentials,
    Level,
    Login,
    SignUp,
    UserInfoResponse
} from "../globalTypes";

export interface ApiState {
    levelCallStatus: ApiLoadingState;
    signUpStatus: ApiLoadingState;
    loginStatus: ApiLoadingState;
}

const initialState: ApiState = {
    levelCallStatus: ApiLoadingState.IDLE,
    signUpStatus: ApiLoadingState.IDLE,
    loginStatus: ApiLoadingState.IDLE
};

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        levelCallStatus: (state, action: PayloadAction<ApiLoadingState>) => {
            state.levelCallStatus = action.payload;
        }
    },
});

export const fetchAndSetNextLevel = (levelNumber :number): AppThunk => (dispatch, getState) => {
    if(levelNumber > LAST_LEVEL - 1){
        console.log('ran out of levels');
        return
    }

    dispatch({type:"api/levelCallStatus", payload:'loading'});
    return axios.get(API_LEVEL_URL + "level-" + levelNumber + ".json")
        .then(({ data }) => {
            dispatch({type:"api/levelCallStatus", payload:'success'})
            dispatch({type:"manager/nextLevel", payload:data as Level});
        })
        .catch((error) =>{
            console.log(error);
            dispatch({type:"api/levelCallStatus", payload:'error'})
        })
}

export const postSignUpDetails = (signUpValues :SignUp): AppThunk => (dispatch, getState) => {

    dispatch({type:"api/signUpStatus", payload: ApiLoadingState.LOADING })
    dispatch({type: "user/status", payload: 'loading' })

    let postCreateUser :ApiCreateUserAccount = {} as ApiCreateUserAccount;
    postCreateUser.name = signUpValues.userName;
    postCreateUser.email = signUpValues.email;
    postCreateUser.password = signUpValues.password;

    return axios.post(API_EXPVI_URL + "api/v1/vim-user", postCreateUser)
        .then(({ data }) => {

            dispatch({type: "api/signUpStatus", payload: ApiLoadingState.SUCCESS});
            dispatch({type: "user/userResponseData", payload: data as UserInfoResponse });
            dispatch({type: "user/status", payload: 'loggedIn' });

        })
        .catch((error) =>{
            if(error.response && error.response.status) {
                switch (error.response.status) {
                    case 400: {
                        dispatch({type: "api/signUpStatus", payload: ApiLoadingState.ERRORUSEREXISTS})
                        break;
                    }
                    case 500: {
                        dispatch({type: "api/signUpStatus", payload: ApiLoadingState.SERVERERROR})
                        break;
                    }
                    default : {
                        dispatch({type: "api/signUpStatus", payload: ApiLoadingState.UNKNOWNERROR})
                        break;
                    }
                }
            }
        })
}

export const postLoginCredentials = (loginValues :Login): AppThunk => (dispatch, getState) => {

    dispatch({type:"api/loginStatus", payload:'loading'});
    let postLoginCredentials :ApiLoginCredentials = {} as ApiLoginCredentials;
    postLoginCredentials.email = loginValues.email;
    postLoginCredentials.password = loginValues.password;

    return axios.post(API_EXPVI_URL + "api/v1/vim-user/login", postLoginCredentials)
        .then(({ data }) => {
            dispatch({type:"api/loginStatus", payload:'success'});
            dispatch({type: "user/userResponseData", payload: data as UserInfoResponse });

            //@TODO: we want to get the users level data when they sign in.
            //dispatch({type:"user/nextLevel", payload:data as UserSignUpResponse});

        })
        .catch((error) =>{
            console.log('error in login');
            console.log(error);
            dispatch({type:"api/levelCallStatus", payload:'error'})
        })
}

export const setUserLevelMetadata = (): AppThunk => (dispatch, getState) => {
    const state = getState();

    if (state.user.vimUserDetails === undefined) return

    dispatch({type:"api/setUserLevelHistory", payload:'loading'});
    const token = state.user.accessToken;
    const userId = state.user.vimUserDetails.vimUserIdentity;

    const data = {
        //app_metadata: {levelHistory: JSON.stringify(state.user.levelHistory)}
        app_metadata: {heellffffo: "ok"}

    };
    const config = {
        headers: {
            'authorization': `Bearer ${token}`,
            'content-type': 'application/json'
        }
    }
    return axios.patch(`https://${AUTH0_DOMAIN_DEV}/api/v2/users/${userId}`,
        data,
        config).then((response) => {
            dispatch({type:"api/setUserLevelHistory", payload:response})
    });
}


export const {levelCallStatus} = apiSlice.actions;

export const selectLevelCallStatus = (state: RootState) => state.api.levelCallStatus;

export default apiSlice.reducer;


