import React from "react";
import '../../app/theme/Modal.css';
import '../../app/theme/Form.css';
import { selectModal } from '../uiSlice';
import { LoginModal } from './LoginModal';
import { SignUpModal } from './SignUpModal';
import { StripeModal } from './StripeModal';
import { useAppDispatch} from "../../../app/hooks";
import { useAppSelector } from '../../../app/hooks';
import {UserInterfaceModal} from "../../../globalTypes";

interface ModalRendererProps {
    isLoading: boolean
}

export function SecondaryPanel(props: ModalRendererProps) {
    let activeModalName = useAppSelector(selectModal);
    let modalToRender :JSX.Element | null = null;
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch({type:'ui/modal', payload:UserInterfaceModal.NONE});
    }
    switch (activeModalName) {
        case 'NONE': {
            modalToRender = null;
            break;
        }
        case 'LOGIN': {
            modalToRender = <LoginModal/>
            break;
        }
        case 'SIGNUP': {
            modalToRender = <SignUpModal/>
            break;
        }
        case 'STRIPE': {
            modalToRender = <StripeModal/>
            break;
        }
        default: {
            modalToRender = null;
            break;
        }
    }

    return (
        <div className={"modalWrapper"}>
            <div className={"modalScreen"}>
            </div>
            <div className={"modal"}>
                <button className={"modalClose"} onClick={handleClick}>
                    &#x2715;
                </button>
                {modalToRender}
            </div>
        </div>
    )
}

export default SecondaryPanel
