import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultState} from '../../constants/defaultStates';
import {UserInterface, UserInterfaceScreen, UserInterfaceModal, CookieConsentStatus} from "../../globalTypes";
import {RootState, AppThunk} from "../../app/store";

const initialState: UserInterface = defaultState.ui;

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        screen: (state, action: PayloadAction<UserInterfaceScreen>) => {
            state.screen = action.payload;
        },
        modal: (state, action: PayloadAction<UserInterfaceModal>) => {
            state.modal = action.payload;
        }
    },
});

export const {screen, modal } = uiSlice.actions;

export const uiThunk = (): AppThunk => (
    dispatch,
    getState
) => {
    dispatch({type:'ui/example', payload:'test'});

};

export const cookieConsent = (cookieConsentStatus :CookieConsentStatus): AppThunk => (
    dispatch,
    getState
) => {

    dispatch({type:'ui/cookieConsent', payload:CookieConsentStatus.ACCEPT});

};

export const cookieRefused = (): AppThunk => (
    dispatch,
    getState
) => {
    dispatch({type:'ui/cookieConsent', payload:CookieConsentStatus.DENY});
}

export const selectScreen = (state: RootState) => state.ui.screen;
export const selectModal = (state: RootState) => state.ui.modal;

export default uiSlice.reducer;
