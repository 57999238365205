import React from 'react';
import { User } from '@auth0/auth0-react';
import styles from '../menu/UserMenu.module.css';
import LoginButton from "../app/common/LoginButton";
import StripeButton from "../app/common/StripeButton";
import HeaderProfile from "./HeaderProfile";
import profPic from '../../assets/images/anonUser.png';

interface AnonUserMenuProps {
    userId: string
}

export function AnonUserHeader(props: AnonUserMenuProps) {

    const user :Partial<User> = {name:props.userId, picture: profPic}

    return (
        <div className={styles.vimUserMenu}>
            {/*<HeaderProfile user={user}/>
            <div className={'menu-login-wrapper'}>
                <LoginButton/>
                <StripeButton/>
            </div>*/}
        </div>
    );
}