import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {modal} from "../uiSlice";
import {UserInterfaceModal} from "../../../globalTypes";
import { loadStripe } from "@stripe/stripe-js";
import {clientSecret, selectClientSecret} from "../../app/stripe/stripeSlice";
import {CheckoutForm} from "../../app/stripe/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

interface StripeModalProps {
}

export function StripeModal(props: StripeModalProps) {
    const dispatch = useAppDispatch();
    let secret = useAppSelector(selectClientSecret);

    const stripePromise = loadStripe("");

    const handleClick = () => {
        dispatch(modal(UserInterfaceModal.STRIPE));
    }

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("http://localhost:8029/api/v1/vim-user/createPaymentIntent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "vimArcardePremium" }] }),
        })
            .then((res) => res.json())
            .then((data) => dispatch(clientSecret(data.clientSecret)));
    }, []);

    const appearance : {theme: "stripe" | "night" | "flat" | "none" | undefined } = {
        theme : 'stripe',
    };
    const options = {
        secret,
        appearance,
    };

    return (
        <div className="App">
            {secret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    );
}

export default StripeModal

