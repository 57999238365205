import React from "react";
import { User } from "@auth0/auth0-react";
import './theme/SecondaryPanel.css';
import LevelCompletion from "./LevelCompletion";

interface SecondaryPanelProps {
    user: User | undefined
    isAuthenticated: boolean
    isLoading: boolean
}

export function SecondaryPanel(props: SecondaryPanelProps) {

    return (
        <LevelCompletion user={props.user} isAuthenticated={props.isAuthenticated} isLoading={props.isLoading} />
    )
}

export default SecondaryPanel
