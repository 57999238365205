import React from 'react';
import './App.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH0_DOMAIN_DEV, AUTH0_CLIENTID} from "./constants/constants";
import App from './App';

const AuthAppWrapper = () => {

    let audience = `https://${AUTH0_DOMAIN_DEV}/api/v2/`

    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN_DEV}
            clientId={AUTH0_CLIENTID}
            //scope={"openid profile email read:current_user read:users update:users update:users_app_metadata update:current_user_metadata create:users_app_metadata read:users_app_metadata"}
            audience={audience}
            redirectUri={window.location.origin}
            scope="read:current_user update:current_user_metadata">

            <App/>
        </Auth0Provider>
    );
}

export default AuthAppWrapper;
