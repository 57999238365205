import React from "react";
import {useAppDispatch} from "../../../app/hooks";
import {modal} from "../uiSlice";
import {UserInterfaceModal} from "../../../globalTypes";
import {SignUpForm} from "../../auth/SignUpForm";

interface SignUpModalProps {
}

export function SignUpModal(props: SignUpModalProps) {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(modal(UserInterfaceModal.LOGIN));
    }

    return (
        <div>
            <SignUpForm />
            <div>
                <p>Already have an account?</p>
                <button onClick={handleClick} >Login</button>
            </div>
        </div>
    )
}

export default SignUpModal

