import { StateEffect, StateField } from "@codemirror/state";
import {Decoration, DecorationSet, EditorView} from "@codemirror/view";

export const underlineMark = Decoration.mark({
  attributes: {class: "cm-bold-red"}
})
export const underlineLine = Decoration.line( {
  attributes: {class: "cm-bold-red"}
})
export const addUnderline = StateEffect.define<{ from: number, to: number }>();
export const highlightLine = StateEffect.define<{ from: number, to: number }>();
export const resetHighlightedLines = StateEffect.define<{}>();
export const identityEffect = StateEffect.define<{from: number, to: number}>();

export const underlineField = StateField.define<DecorationSet>({
  // Start with an empty set of decorations
  create() { return Decoration.none },
  // This is called whenever the editor updates—it computes the new set
  update(underlines :DecorationSet , tr) {
    underlines = underlines.map(tr.changes)
    for (let e of tr.effects) if (e.is(addUnderline)) {
      underlines = underlines.update({
        add: [underlineMark.range(e.value.from, e.value.to)]
      })
    }
    for (let e of tr.effects) if (e.is(highlightLine)) {
      underlines = underlines.update({
        add: [underlineLine.range(e.value.from, e.value.to)]
      })
    }
    for (let e of tr.effects) if (e.is(resetHighlightedLines)) {
      underlines = underlines.update({
        filter: (() => {return false}),
      })
    }
    return underlines
  },
  // Indicate that this field provides a set of decorations
  provide: f => EditorView.decorations.from(f)
})
