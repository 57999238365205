import { User } from "@auth0/auth0-react";
import "./theme/ActiveScreen.css";
import React from "react";
import EditorScreen from "./screens/EditorScreen";

interface ActiveScreenProps {
    active: boolean
    user: User | undefined
}

export function ActiveScreen(props: ActiveScreenProps) {

    if(!props.active){
        return null;
    }
    /*
        if(error) {
            console.log(error);
            return <div>There was an authentication error.</div>
        }*/

    return (
        <div className={"active-screen"}>
            <EditorScreen active={true} user={ {} as User } />
            {/*
            <h1 className={'game-name-heading'}>Pong (replace with redux prop)</h1>
            <Button onClick={handleGetStartedClick}
                    disabled={!props.active}
                    color={'primary'}>
                <span>Get Started</span>
            </Button> */}
        </div>
    )
}

export default ActiveScreen