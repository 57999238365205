import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {defaultState} from "../../constants/defaultStates";
import {EditorTracker, GridBlock, Rectangle1P} from "../../globalTypes";
import {
    CHARACTER_WIDTH,
    EDITOR_HEIGHT, EDITOR_TOTAL_LEFT_OFFSET, EDITOR_TOTAL_TOP_OFFSET,
    EDITOR_WIDTH, LINE_HEIGHT,
    MAX_CHARACTERS_IN_VIEWPORT
} from "../../constants/constants";
import {isDefinedAndNotNull} from "../../common/logic";

const initialState: EditorTracker = defaultState.codeEditorTracker;

export const cm6EditorSlice = createSlice({
    name: 'cm6Editor',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        focusedStartingPos: (state, action: PayloadAction<{ x: number, y: number }>) => {
            state.focusedStartingPos = action.payload;
        },
        focusLength: (state, action: PayloadAction<number>) => {
            state.focusLength = action.payload;
        },
        gridMatrix: (state, action: PayloadAction<string[]>) => {
            let placeholder = [] as GridBlock[][];

            action.payload.forEach((actionString, indexI) => {
                if(isDefinedAndNotNull(placeholder[indexI])) {
                    // @ts-ignore
                    [...actionString].forEach((actionChar, indexJ) => {
                        placeholder[indexI][indexJ] = {line: indexI, char: indexJ, entry: actionChar, source: 'cm6'}
                    })
                }
                else {
                    placeholder[indexI] = new Array(MAX_CHARACTERS_IN_VIEWPORT).fill(" ").map((value, indexNew) => {
                        //indexed from 1.
                        return {line: indexI, char: indexNew, entry: ' ', source: 'cm6'}
                    });
                    // @ts-ignore
                    [...actionString].forEach((actionChar, indexJ) => {
                        placeholder[indexI][indexJ] = {line: indexI, char: indexJ, entry: actionChar, source: 'cm6'}
                    })
                }
            })
            state.gridMatrix = placeholder;
        },
        content: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
        calculateOffsets: (state, action: PayloadAction<Rectangle1P | undefined>) => {
            if (action.payload === undefined
                || action.payload.x === undefined
                || action.payload.y === undefined ) return

            const offsetTop = (action.payload.y - EDITOR_TOTAL_TOP_OFFSET) * -1;
            const offsetLeft = (action.payload.x - EDITOR_TOTAL_LEFT_OFFSET) * -1;

            state.editorOffsets.editorTop = offsetTop;
            state.editorOffsets.editorBottom = offsetTop + EDITOR_HEIGHT;
            state.editorOffsets.editorLeft = offsetLeft;
            state.editorOffsets.editorRight = offsetLeft + EDITOR_WIDTH;

            //first visible line.
            state.offsetLineIndex = Math.floor(offsetTop / LINE_HEIGHT);

            //first visible char.
            state.offsetCharIndex = Math.floor(offsetLeft / CHARACTER_WIDTH);
        },
    },
});

export const {focusedStartingPos, focusLength, gridMatrix, content, calculateOffsets } = cm6EditorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFocusedStartingPos = (state: RootState) => state.cm6Editor.focusedStartingPos;
//export const selectFocusLength = (state: RootState) => state.cm6Editor.focusLength;
//export const selectGridMatrix = (state: RootState) => state.cm6Editor.gridMatrix;
export const selectInitialChange = (state: RootState) => state.cm6Editor.initialChange;
export const selectContent = (state: RootState) => state.cm6Editor.content;


export default cm6EditorSlice.reducer;
