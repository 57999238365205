import {GridLocation, GridLocationChar, Point, Rectangle4P, TextTask} from '../globalTypes'
import {CHARACTER_WIDTH, EDITOR_GUTTER_WIDTH, LINE_HEIGHT} from "../constants/constants";

export const Rectangle2 = (boundingA :Point, boundingB :Point) :Rectangle4P => {

    return {
        topLeft: {x: boundingA.x, y: boundingA.y},
        topRight: {x: boundingB.x, y: boundingA.y},
        bottomLeft: {x: boundingA.x, y: boundingB.y},
        bottomRight: {x: boundingB.x, y: boundingB.y},
    }
}

export const getAbsoluteScreenGridLocations = ( Rectangle :Rectangle4P) => {
    const x :GridLocation[] = Object.values(Rectangle).map( propertyValue => {
        //eg. {line: 0, char: 0} or {line: 16, char: 30} etc.
        return getAbsoluteGridLocationForPoint(propertyValue);

    })
    return x
}

export const getAbsoluteGridLocationForPoint = (point :Point) :GridLocation => {
    const line = getAbsoluteLineFromPoint(point);
    const char = getAbsoluteCharFromPoint(point);

    return {line:line, char:char};
}

export const getAbsoluteLineFromPoint = (point :Point) :number => {
    return Math.floor(point.y / LINE_HEIGHT);
}

export const getAbsoluteCharFromPoint = (point :Point) :number => {
    return Math.floor((point.x - EDITOR_GUTTER_WIDTH ) / CHARACTER_WIDTH);
}

export const gridLocationCharsFromTextTasks = (textTasks :TextTask[]): GridLocationChar[][] => {
    const wrappers = textTasks.map( (textTask :TextTask) => {
        let x :GridLocationChar[] = [];
        for (let i = 0; i < textTask.text.length; i++){
            x.push({line:textTask.startLine, char:textTask.startChar + i, item:textTask.text[i]});
        }
        return x
    });
    return wrappers;
}
//!IMPORTANT - this reference frame begins from the top left corner of the editor.
export const getPointFromGridLocationCenter = (gridLocation :GridLocation | GridLocationChar) :Point => {
    return {x:gridLocation.char * CHARACTER_WIDTH + (.5 * CHARACTER_WIDTH), y:gridLocation.line * LINE_HEIGHT + .5 * LINE_HEIGHT};
}
/*
export const getRelativeGridSquaresFromAbsoluteGridLocationsAndViewPort = (locations :GridLocation[], viewport :React.MutableRefObject<EditorView | null>) :GridLocation => {
    //console.log(locations);
    //console.log(viewport?.current?.contentDOM.getBoundingClientRect());
    const totalEditorHeight = viewport?.current?.contentDOM?.getBoundingClientRect() as Rectangle1P
    return {line:1, char:1}
}
*/