import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../../../app/store';
import {
    GridLocation,
    GridEffect,
    Point,
    PongTracker,
    LevelStatus, SessionStatus, PongBall, TextTask, TextTaskStatus
} from '../../../globalTypes';
import {
    EDITOR_WIDTH,
    EDITOR_HEIGHT,
    BALL_RADIUS,
    EDITOR_GUTTER_WIDTH
} from '../../../constants/constants'
import {defaultState} from '../../../constants/defaultStates';
import {
    getAbsoluteScreenGridLocations,
    getPointFromGridLocationCenter,
    Rectangle2
} from "../../../common/geometry";
import React from "react";
import {EditorView} from "@codemirror/view";
import {proceed} from "../managerSlice";

const initialState: PongTracker = defaultState.pong;

export const pongSlice = createSlice({
    name: 'pong',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        wallCheck: (state) => {
            for (const newBall of state.balls) {
                //detect collisions with top edge.
                if (newBall.location.y + newBall.dy < 0 + BALL_RADIUS) {
                    newBall.dy = -newBall.dy + 0.1
                }
                //detect collisions with bottom edge.
                if (newBall.location.y + newBall.dy > EDITOR_HEIGHT - BALL_RADIUS) {
                    newBall.dy = -newBall.dy + 0.1
                }
                //detect collisions with left edge.
                if (newBall.location.x + newBall.dx < 0 + BALL_RADIUS + EDITOR_GUTTER_WIDTH) {
                    newBall.dx = -newBall.dx
                }
                //detect collisions with right edge.
                if (newBall.location.x + newBall.dx > EDITOR_WIDTH - BALL_RADIUS) {
                    newBall.dx = -newBall.dx
                }

            }

        },
        ballLocation: (state) => {
            for (const newBall of state.balls) {
                newBall.location.x = newBall.dx + newBall.location.x;
                newBall.location.y = newBall.dy + newBall.location.y;

            }
        },
        balls: (state, action: PayloadAction<PongBall[]>) => {
            state.balls = action.payload;
        },
        reduceLife: (state) => {
            state.lives--;
        },
        primaryStateEffects: (state, action: PayloadAction<GridEffect[]>) => {
            state.primaryStateEffects = action.payload;
        },
        secondaryStateEffects: (state, action: PayloadAction<Array<{ x: number, y: number, type: string }>>) => {
            state.secondaryStateEffects = action.payload;
        },
        tertiaryStateEffects: (state, action: PayloadAction<GridEffect[]>) => {
            state.tertiaryStateEffects = action.payload;
        },
        resetPongState: (state, action: PayloadAction<PongTracker>) => {
            state = action.payload;
        }
    },
})

export const updatePongState = (viewport: React.MutableRefObject<EditorView | null>): AppThunk => (dispatch, getState) => {
    const state = getState();

    const absoluteGridSquares: GridLocation[][] = state.pong.balls.map((ball) => {
        let boundingRectA: Point = {x: ball.location.x - ball.radius, y: ball.location.y - ball.radius};
        let boundingRectB: Point = {x: ball.location.x + ball.radius, y: ball.location.y + ball.radius};
        return getAbsoluteScreenGridLocations(Rectangle2(boundingRectA, boundingRectB));
    });

    const locations = absoluteGridSquares.flat();

    // changed to call once, synchronously. can probably remove in a few commits.
    //dispatch(renderHighlightTargetLocation([state.manager.level.targetLocation]));
    //dispatch(renderEditorFeatures(state.manager.level.renderEditorFeatures));
    dispatch(wallCheck());
    dispatch(ballLocation());
    dispatch(nextLevelCheck(locations));
    dispatch(textCollisionCheck(locations));
}

export const nextLevelCheck = (locations: GridLocation[]): AppThunk => (dispatch, getState) => {
    const state = getState();
    const targetLocation = state.manager.level.targetLocation;
    const textTasks = state.manager.level.textTasks;

    locations.forEach((location) => {
        if (location.line === targetLocation.line && location.char === targetLocation.char) {

            if (!textTasksFailed(textTasks)) {
                dispatch(proceed(state.manager.level));
            } else {
                //@TODO: prompt to show reason user lost (ie. not all text tasks complete.)
                dispatch(textWasHit(getPointFromGridLocationCenter(targetLocation)));
            }
        }
    });
}

export const textTasksFailed = (textTasks: TextTask[]) => {
    const numberOfFailedTests = textTasks.filter((task) => {return task.status !== TextTaskStatus.COMPLETE}).length;
    console.log({numberOfFailedTests});
    return numberOfFailedTests > 0;
}

export const textCollisionCheck = (locations :GridLocation[]) : AppThunk => (dispatch, getState) => {
    const state = getState();
    const gridMatrix = state.cm6Editor.gridMatrix;
    const offsetLineIndex = state.cm6Editor.offsetLineIndex;
    const offsetCharIndex = state.cm6Editor.offsetCharIndex;

    for (let location of locations) {

        let offsetLine = location.line + offsetLineIndex;
        let offsetChar = location.char + offsetCharIndex;

        if(typeof gridMatrix[offsetLine] === "object" &&
            typeof gridMatrix[offsetLine][offsetChar] === "object" ){

            //const matrixObj = gridMatrix[location.line][location.char];
            const offsetMatrixObj = gridMatrix[offsetLine][offsetChar];

            if(offsetMatrixObj.entry !== " "){

                /* getPointFromGridLocationCenter returns a point when this if clause fires;
                it found a non-space ascii character. */
                dispatch(textWasHit(getPointFromGridLocationCenter(offsetMatrixObj)));
                break;
                //if collisions seem broken, this ^ change was added when CSS was introduced to move editor from topleftmost point.
                //                dispatch(textWasHit(getPointFromGridLocationCenter(matrixObj))); ????
            }
        }
    }
}

export const textWasHit = (ballPos: Point): AppThunk => (dispatch, getState) => {

    const state = getState();
    //updates the render editor with a red highlight line.
    dispatch(secondaryStateEffects([{x: ballPos.x, y: ballPos.y, type: "highlightLine"}]));

    //stop the ball from moving any further.
    //dispatch(updateCanvasToggle(false));
    dispatch({type:"manager/textWasHit", payload: 1});

    dispatch({type:"manager/decrementRemainingLives", payload: 1});

    dispatch(updatePongLevelStatus(state.manager.levelStatus, LevelStatus.COLLISION));
}

export const updatePongLevelStatus = (previousStatus: LevelStatus, newStatus :LevelStatus): AppThunk => (dispatch, getState) => {

    const state = getState();
    dispatch({type:"manager/levelStatus", payload: newStatus});
    switch(newStatus){
        case LevelStatus.ACTIVE: {
           break;
        }
        case LevelStatus.COLLISION: {
            if(state.manager.remainingLives === 0){
                dispatch({type:"manager/sessionStatus", payload: SessionStatus.GAME_OVER});
                //show some kind of LOAD SAVED ^
                dispatch({type:"manager/levelStatus", payload:LevelStatus.IDLE});
            }
            else {
                updatePongLevelStatus(LevelStatus.COLLISION, LevelStatus.RESTART_MODE)
            }
            break;
        }
        case LevelStatus.IDLE: {
            dispatch({type:"manager/levelStatus", payload:LevelStatus.IDLE});
            break;
        }
        case LevelStatus.RESTART_MODE: {
            dispatch({type:"manager/levelStatus", payload:LevelStatus.RESTART_MODE});
            break;
        }
        case LevelStatus.LOADING: {
            console.log('loadingpleasewait');
            break;
        }

    }
}

export const getInitialState = () => {
    return initialState;
}

export const {wallCheck, ballLocation, reduceLife, resetPongState, secondaryStateEffects, primaryStateEffects, tertiaryStateEffects} = pongSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPongTracker = (state: RootState) => state.pong
export const selectLineStateEffects = (state: RootState) => state.pong.secondaryStateEffects;
export const selectMarkStateEffects = (state: RootState) => state.pong.primaryStateEffects;

export default pongSlice.reducer;