import {CSSProperties} from "react"
import { BrickBreakerTracker, PongTracker, SpaceInvadersTracker } from '../../globalTypes';
import { Pong } from './pong/Pong';
import { SpaceInvaders } from './spaceInvaders/SpaceInvaders';
import { BrickBreaker } from './brickBreaker/BrickBreaker';

interface CanvasTrackerProps {
    canvasTracker: PongTracker | SpaceInvadersTracker | BrickBreakerTracker
    canvasType: 'pong' | 'spaceInvaders' | 'brickBreaker'
}

const style: CSSProperties = {
    position:'absolute',
    pointerEvents:'none',
    zIndex:2,
    border: '0px'
};

const game = {
    pong : Pong,
    spaceInvaders: SpaceInvaders,
    brickBreaker: BrickBreaker
}

const GameCanvas = (props: CanvasTrackerProps) => {
    let rendered = game[props.canvasType];
    return (
       rendered(style)
    )
  
  };
  
  export { GameCanvas }