import { StateEffect, StateField } from "@codemirror/state";
import {Decoration, DecorationSet, EditorView} from "@codemirror/view";

export const styleMatchedTextMark = (letter: string) => {
    return Decoration.mark({
        attributes: {"class": "cm-arcade-matched-text", "data-letter": letter}
    });
}
export const addStyleMatchedText = StateEffect.define<{from: number, to: number, letter: string}>();
export const shouldResetStyleMatched = StateEffect.define<{}>();
export const identityEffect = StateEffect.define<{from: number, to: number}>();

export const styleMatchedText = StateField.define<DecorationSet>({
    // Start with an empty set of decorations
    create() { return Decoration.none },
    // This is called whenever the editor updates—it computes the new set
    update(styleTargets :DecorationSet , tr) {
        //highlights = highlights.map(tr.changes)
        for (let e of tr.effects) {

            if (e.is(addStyleMatchedText)) {

                styleTargets = styleTargets.update({
                    add: [styleMatchedTextMark(e.value.letter).range(e.value.from, e.value.to)]
                })
            }

            if (e.is(shouldResetStyleMatched)) {

                styleTargets = styleTargets.update({
                    filter: (() => {return false}),
                })
            }
        }
        return styleTargets
    },
    // Indicate that this field provides a set of decorations
    provide: f => EditorView.decorations.from(f)
})