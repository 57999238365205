import React from "react";
import {Login, LoginErrors} from "../../globalTypes";
import {Field, Form} from 'react-final-form';
import {useAppDispatch} from "../../app/hooks";
import {postLoginCredentials} from '../../api/apiSlice';
import {emailIsValid} from "./util/common";

export const LoginForm = () => {

    const dispatch = useAppDispatch();

    const onSubmit = async (values: Login) => {

        if(values.dateOfBirthCaptcha !== '') {
            dispatch(postLoginCredentials(values));
        }
    }

    return(
        <Form
            onSubmit={onSubmit}
            validate={(values: Login) => {
                const errors = {} as LoginErrors
                if (!values.email) {
                    errors.email = 'Required';
                }
                if(!emailIsValid(values.email)){
                    errors.email = 'Invalid email';
                }
                if (!values.password) {
                    errors.password = 'Required';
                }
                if(values.dateOfBirthCaptcha){
                    errors.dateOfBirthCaptcha = 'Ineligible date of birth';
                }
                return errors
            }}

            render={({handleSubmit, form, submitting, pristine, values}) => (
                <>
                    <div className={"modalFormHeader"}>
                        <h1>Login</h1>
                    </div>
                    <form onSubmit={handleSubmit} className={"loginForm form"}>
                        <Field name="email">
                            {({input, meta}) => (
                                <div className={'emailField'}>
                                    <label>Email</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="text" placeholder="Email"/>
                                </div>
                            )}
                        </Field>
                        <Field name="password">
                            {({input, meta}) => (
                                <div className={'passwordField'}>
                                    <label>Password</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="password" placeholder="Password"/>
                                </div>
                            )}
                        </Field>
                        <div className="buttons">
                            <button className={"login submitButton"} type="submit" disabled={submitting}>
                                Submit
                            </button>
                        </div>
                    </form>
                </>
            )}
        />
    )
}
