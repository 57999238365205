export const emailRegExp = RegExp(
    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
)
export const basicFrontEndPasswordValidation = (password :string) => {
    if(password.length < 8 ) {
        return false
    }
    return true;
}
export type Validation = {
    isError: boolean,
    rest: any
};

export const setCookie = (cName :string, cValue :string, expDays :number) => {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
};

export const getCookie = (cName :string) => {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
          if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res;
}

export const emailIsValid = (userInput :string) :boolean => {
    const valid = emailRegExp.test(userInput);
    return valid;
};

export const passwordIsValid = (userInput :string) :boolean => {
    const valid = basicFrontEndPasswordValidation(userInput);
    return valid;
};

export const confirmPasswordIsValid = (firstPassword :string, confirmationPassword :string) => {
    if(firstPassword === confirmationPassword) return true;
    return false;
}

export const dateOfBirthIsValid = (userInput :string) :boolean => {
    if (userInput === '') return true;
    return false;
};
