import {Button} from "@mui/material";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface LogoutButtonProps {
    handleClickCallBack: () => void
}

const LogoutButton = (props: LogoutButtonProps) => {
    const { logout } = useAuth0();

    const handleClick = () => {
        logout({ returnTo: window.location.origin });
        props.handleClickCallBack();
    }

    return <Button onClick={handleClick}>Log Out</Button>;
};

export default LogoutButton;