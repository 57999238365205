import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultState} from '../../../constants/defaultStates';
import {StripeInterface} from "../../../globalTypes";
import {RootState} from "../../../app/store";

const initialState: StripeInterface = defaultState.stripe;

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        clientSecret: (state, action: PayloadAction<string>) => {
            state.clientSecret = action.payload;
        },
    },
});

export const {clientSecret} = stripeSlice.actions;

/*
export const stripeThunk = (): AppThunk => (
    dispatch,
    getState
) => {
    dispatch({type:'ui/example', payload:'test'});

};*/

export const selectClientSecret = (state: RootState) => state.stripe.clientSecret;

export default stripeSlice.reducer;
