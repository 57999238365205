//import {useAppDispatch} from "../../../app/hooks";
import { User } from "@auth0/auth0-react";
import Manager from "../../scene/Manager";
import React from "react";
import "../theme/EditorScreen.css";
import terminalWrapper from "../theme/img/vimStrippedDown.jpg";


interface EditorScreenProps {
    active: boolean
    user: User | undefined
}

export function EditorScreen(props: EditorScreenProps) {

    if(!props.active){
        return null;
    }

    return (
        <div className={"editorscreen-wrapper"}>
            <img className={"terminal-wrapper"} alt={'terminal-wrapper'} src={terminalWrapper} />
            <Manager/>

            {/*
            <Button onClick={handleGoSomewhereClick}
                    disabled={!props.active}
                    color={'primary'}>
                <span>Go somewhere other than editor screen</span>
            </Button>*/}
        </div>
    )
}

export default EditorScreen