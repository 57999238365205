import React, {useEffect, useRef} from "react";
import {highlightSpecialChars, drawSelection, highlightActiveLine, ViewUpdate} from '@codemirror/view';
import {javascript} from "@codemirror/lang-javascript"
import {EditorView, ViewPlugin} from '@codemirror/view';
import {EditorState} from '@codemirror/state';
import {history} from '@codemirror/history';
import {foldGutter} from '@codemirror/fold';
import {indentOnInput} from '@codemirror/language';
import {lineNumbers} from '@codemirror/gutter';
import {bracketMatching} from '@codemirror/matchbrackets';
import {closeBrackets} from '@codemirror/closebrackets';
import {highlightSelectionMatches } from '@codemirror/search';
import {autocompletion} from '@codemirror/autocomplete';
import {rectangularSelection} from '@codemirror/rectangular-selection';
import {defaultHighlightStyle} from '@codemirror/highlight';

import './CM6Editor.css';

import {identityEffect, resetHighlightedLines, underlineField} from './customPlugins/underlineField';
import {highlightPoint,} from "./customPlugins/highlightPoint";
import {addStyleTargetLocation, resetTargetLocation, targetLocation} from "./customPlugins/targetLocation";
import {useAppSelector, useAppDispatch} from '../../app/hooks';

import {
    EDITOR_WIDTH,
    EDITOR_HEIGHT,
    SAILOR_BLUE,
    MINT_GREEN,
    STRING_COLOR,
    COMMENT_COLOR, VARIABLE_COLOR, FUNCTION_NAME_COLOR, INTEGER_COLOR
} from '../../constants/constants'

import {
    editorDetailsUpdated,
    selectDeltaTime
} from '../scene/managerSlice';
import {vim} from '@replit/codemirror-vim';
import {
    addStyleEditorFeature,
    editorFeature, gameState,
    gameStateStateEffectType,
    resetStyleEditorFeature
} from "./customPlugins/editorFeature";
import {GridLocation, GridLocationChar, RenderEditorFeature} from "../../globalTypes";
import {featuresToGridLocations, getDocPositionFromGridLocation} from "./utility/common";
import {addStyleMatchedText, shouldResetStyleMatched, styleMatchedText} from "./customPlugins/styleMatchedText";
import UIControls from "../app/UIControls";
import {vimSetKeyDown} from "./customPlugins/vim/vimEffect";

interface EditorProps {
    content: string
    renderContent: string
    shouldResetSecondaryFx: boolean
    shouldResetStyleMatched: boolean
    styleMatchedText: GridLocationChar[]
    targetLocation: GridLocation
    renderEditorFeatures: RenderEditorFeature[]
}

let Theme = EditorView.theme({
    "&": {
        color: MINT_GREEN,
        backgroundColor: SAILOR_BLUE,
        height: EDITOR_HEIGHT + "px",
        width: EDITOR_WIDTH + "px",
        overflow: "hidden"
    },
    ".cm-content": {
        caretColor: "#0e9",
        padding: "0px 0px"
    },
    ".ͼa": {
        color: FUNCTION_NAME_COLOR,
    },
    ".ͼc": {
        color: INTEGER_COLOR,
    },
    ".ͼd": {
        color: STRING_COLOR,
    },
    ".ͼl": {
        color: COMMENT_COLOR,
    },
    ".ͼf": {
        color: VARIABLE_COLOR,
    },
    ".cm-activeLine":{
        backgroundColor:"rgba(0,0,0,0.25)"
    },
    "&.cm-focused .cm-cursor": {
        borderLeftColor: "#0e9"
    },
    "&.cm-focused .cm-selectionBackground, ::selection": {
        backgroundColor: "#074"
    },
    ".cm-gutters": {
        backgroundColor: SAILOR_BLUE,
      //  color: "#FFFF8F", yellow.
        color: MINT_GREEN,
        border: "none",
        width: "24px"
    },
    ".cm-gutter": {
        width: "12px",
        overflow: "visible"
    },
    ".cm-line": {
        padding: "0px"
    },
    ".cm-bold-red": {
        fontStyle: "bold",
        backgroundColor: "#cb4154",
        color: "#cb4154",
        textDecoration: "underline 3px #cb4154"
    },
    ".cm-background-gray": {
        backgroundColor: "#eaeaea",
        color:"#eaeaea"
    },
    ".cm-arcade-target-location": {
        display:"inline-block",
        width:"18px",
        position:"relative",
        borderRadius:"50%",
        backgroundImage: "repeating-radial-gradient(#ADBEEF, #010134 20%, green 15%)",
    },
    ".cm-arcade-editor-feature": {
        display:"inline-block",
        position:"relative",
        borderRadius:"96%",
        backgroundImage: "repeating-radial-gradient(rgba(173, 190, 239, 0.2), #010134 42%, rgba(255, 255, 143, 1) 7%)",
    //backgroundImage: "repeating-radial-gradient(rgba(173, 190, 239, .5)"
    //        + ", #010134 3%, rgba(255, 255, 143, .5) 14%)",
    },
    //background-image: repeating-radial-gradient(rgba(173, 190, 239, 0.2), #010134 42%, rgba(255, 255, 143, 1) 7%);
    ".cm-vim-panel": {
        fontFamily:"Press Start 2P",
        backgroundColor: "#eaeaea",
        color:"#fff"
    }

}, {dark: false})

const CodeMirrorSetup = [
    lineNumbers(),
    highlightSpecialChars(),
    history(),
    foldGutter(),
    drawSelection(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    defaultHighlightStyle.fallback,
    bracketMatching(),
    closeBrackets(),
    autocompletion(),
    rectangularSelection(),
    Theme,
    highlightActiveLine(),
    highlightSelectionMatches(),

];

export {CodeMirrorSetup};

export function CM6Editor(props: EditorProps) {

    const editor = useRef<HTMLDivElement>(null);
    const renderEditor = useRef<HTMLDivElement>(null);
    const codeEditorViewRef :React.MutableRefObject<EditorView | null> = useRef<EditorView>(null);
    const renderEditorViewRef :React.MutableRefObject<EditorView | null> = useRef<EditorView>(null);

    const dt = useAppSelector(selectDeltaTime);
    const dispatch = useAppDispatch();

    let timer :NodeJS.Timeout = setInterval( () => {}, 100000);
    let setTimer = (view :EditorView, dt :number) => {
        timer = setInterval(() => {
            view.dispatch({} );
        }, dt > 20 ? dt : 20);
    }

    const onUpdate = ViewPlugin.fromClass(class {
        constructor(view: EditorView) {
            let gameStateField = view.state.field(gameState);
            setTimer(view, gameStateField.deltaTime);
        }

        update(update: ViewUpdate) {
            const newEditorState = update.view.state;
            const textLeaf = newEditorState.doc.toJSON();
            if (codeEditorViewRef === null) return;
            if (renderEditorViewRef === null) return;

            let gameStateField = update.view.state.field(gameState);
            if(gameStateField.deltaTime !== dt) {
                if (codeEditorViewRef.current === null) return;
                clearInterval(timer);
                setTimer(update.view, gameStateField.deltaTime);
            }

            dispatch(editorDetailsUpdated(textLeaf, renderEditorViewRef, codeEditorViewRef));

        }
    })

    useEffect( () => {
        if(codeEditorViewRef.current === null) return;
        setTimeout(() => {
            if(codeEditorViewRef.current === null) return;
            codeEditorViewRef.current.dispatch({
                effects: gameStateStateEffectType.of({deltaTime: dt})
            })
        },1);
    },[dt]);

    useEffect(() => {

        if (editor.current === null) return
        if (codeEditorViewRef.current !== null) return

        const renderState = EditorState.create({
            doc: props.renderContent,
            extensions: [
                Theme,
                lineNumbers(),
                foldGutter(),
                underlineField,
                highlightPoint,
                targetLocation,
                styleMatchedText,
                editorFeature
            ]
        })

        const codeState = EditorState.create({
            doc: props.content,
            extensions: [
                CodeMirrorSetup,
                gameState,
                onUpdate,
                javascript(),
                //Theme,
                vim(),
            ]
        });

        codeEditorViewRef.current = new EditorView({ state: codeState,
                                                        parent: editor.current!,
        });
        renderEditorViewRef.current = new EditorView ( { state: renderState,
                                                                parent: renderEditor.current!});

    }, [props.content, props.renderContent, onUpdate]);

    useEffect(() => {
        if (codeEditorViewRef.current === null) return
        setTimeout(() => {
            if (codeEditorViewRef.current === null) return
            codeEditorViewRef.current.dispatch({
                changes: {from: 0, to: codeEditorViewRef.current.state.doc.length, insert: props.content}
            });
        }, 2);
    }, [props.content]);

    useEffect(() => {
        if (renderEditorViewRef.current === null) return
        setTimeout(() => {
            if (renderEditorViewRef.current === null) return
            renderEditorViewRef.current.dispatch({
                changes: {from: 0, to: renderEditorViewRef.current.state.doc.length, insert: props.renderContent}
            });
        },2);
    }, [props.renderContent]);

    //handle updates to target location just once.
    useEffect(() => {

        if (renderEditorViewRef.current === null) return

        setTimeout(() => {
            if (renderEditorViewRef.current === null) return
            renderEditorViewRef.current.dispatch({
                effects: [resetTargetLocation.of({})]
            });
        },2);

        setTimeout(() => {
            const docPosition = getDocPositionFromGridLocation(renderEditorViewRef, props.targetLocation);
            let targetLocation;
            if (docPosition === null){
                targetLocation = identityEffect.of({from: 0, to: 1});
            }
            else {
                targetLocation = addStyleTargetLocation.of({from: docPosition, to: docPosition + 1 });
            }
            if (renderEditorViewRef.current === null) return

            renderEditorViewRef.current.dispatch({
            effects: [targetLocation]
        });},20);
    }, [props.targetLocation]);

    //handle updates to target location just once.
    useEffect(() => {

        if (renderEditorViewRef.current === null) return

        setTimeout(() => {
            if (renderEditorViewRef.current === null) return

            renderEditorViewRef.current.dispatch({
                effects: [resetStyleEditorFeature.of({})]
            });
        },2);

        setTimeout(() => {
            let gridLocations = featuresToGridLocations(props.renderEditorFeatures);

            const docPositions = gridLocations.map((gridLocation) => {
                return getDocPositionFromGridLocation(renderEditorViewRef, gridLocation)
            })

            const stateEditorFeatures = docPositions.map((docPosition) =>  {
                if(docPosition === null) {return identityEffect.of({from: 0, to: 1})}
                return addStyleEditorFeature.of({from: docPosition, to: docPosition + 1 });
            });
            if (renderEditorViewRef.current === null) return

            renderEditorViewRef.current.dispatch({
                effects: stateEditorFeatures
            });
        },20);
    }, [props.renderEditorFeatures]);


    //handle updates to target location just once.
    useEffect(() => {

        if (renderEditorViewRef.current === null) return

        setTimeout(() => {

            const entries = props.styleMatchedText.map((gridLocation) => {
                return {docPosition: getDocPositionFromGridLocation(renderEditorViewRef, gridLocation), entry: gridLocation.item}
            });

            const stateMatchedTextFeatures = entries.map((entry) => {
                if(entry.docPosition === null) {return identityEffect.of({from: 0, to: 1})}
                return addStyleMatchedText.of({from: entry.docPosition, to: entry.docPosition + 1, letter: entry.entry});
            });

            if (renderEditorViewRef.current === null) return

            renderEditorViewRef.current.dispatch({
                effects: stateMatchedTextFeatures
            });
        },2);

        setTimeout(() => {
            if (renderEditorViewRef.current === null) return

            renderEditorViewRef.current.dispatch({
                effects: [shouldResetStyleMatched.of({})]
            });
        },2000);
    }, [props.styleMatchedText]);

    useEffect(() => {
        setTimeout( () => {
            if (renderEditorViewRef.current === null) return
            if (props.shouldResetSecondaryFx === false) return

            dispatch({type: 'renderEditor/shouldResetSecondaryFx', payload: false});

            renderEditorViewRef.current.dispatch({
                effects: [resetHighlightedLines.of({})]
            });
        }, 200);
    },[props.shouldResetSecondaryFx, dispatch])

    useEffect(() => {
        setTimeout( () => {
            if (renderEditorViewRef.current === null) return
            if (props.shouldResetStyleMatched === false) return

            dispatch({type: 'renderEditor/shouldResetStyleMatched', payload: false});

            renderEditorViewRef.current.dispatch({
                effects: [shouldResetStyleMatched.of({})]
            });
        }, 2);
    },[props.shouldResetStyleMatched, dispatch])


    return (
        <>
            <div className={"editorContainer"} id={"cm6RenderWrapper"} ref={renderEditor}/>
            <div className={"editorContainer"} id={"cm6CodeWrapper"} ref={editor}/>

            <UIControls codeEditorRef={codeEditorViewRef} />
        </>
    );
}


