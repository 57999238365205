import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {defaultState} from '../../constants/defaultStates';
import {Login, SignUp, AuthFormInterface} from "../../globalTypes";
import {RootState, AppThunk} from "../../app/store";

const initialState: AuthFormInterface = defaultState.auth;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        loginForm: (state, action: PayloadAction<Login>) => {
            state.loginForm = action.payload;
        },
        signUpForm: (state, action: PayloadAction<SignUp>) => {
            state.signUpForm = action.payload;
        }
    },
});

export const {loginForm, signUpForm } = authSlice.actions;

export const authThunk = (): AppThunk => (
    dispatch,
    getState
) => {
    dispatch({type:'auth/example', payload:'test'});

};

export const selectLoginForm = (state: RootState) => state.auth.loginForm;
export const selectSignUpForm = (state: RootState) => state.auth.signUpForm;

export default authSlice.reducer;
