import styles from "../scene/Manager.module.css";
import {
    proceed, proceedButton,
    reset,
    restart,
    selectCanvasToggle, selectLevel,
    selectLevelComplete, selectRemainingLives,
    start,
    updateCanvasToggle
} from "../scene/managerSlice";
import React from "react";
import {EditorView} from "@codemirror/view";
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectPongTracker} from "../scene/pong/pongSlice";

export interface UIControlsProps {
    codeEditorRef: React.MutableRefObject<EditorView | null>
}

export function UIControls(props: UIControlsProps) {
    const pongTracker = useAppSelector(selectPongTracker);
    const canvasToggle = useAppSelector(selectCanvasToggle);
    const levelComplete = useAppSelector(selectLevelComplete);
    const remainingLives = useAppSelector(selectRemainingLives);
    const level = useAppSelector(selectLevel);
    const dispatch = useAppDispatch();

    return (
        <>
            <div className={"uiWrapper"}>

                                            <div className={'flexItem first'}>
                                            <button
                                            className={styles.resetButton}
                                            aria-label="Reset"
                                            onClick={() => dispatch(reset(props.codeEditorRef))}
                                            >
                                            Reset
                                            </button>
                                            </div>
                                            <div className={'flexItem'}>
                                            <button
                                            className={styles.button + levelComplete ? ' active' : ''}
                                            aria-label="Next Level"
                                            onClick={() => dispatch(proceedButton(props.codeEditorRef, level))}
                                            >
                                            Proceed.
                                            </button>
                                            </div>
                                            <div className={'flexItem flexItemLarge'}>
                                            <button
                                            className={styles.button + levelComplete ? ' active' : ''}
                                            aria-label="Start"
                                            onClick={() => dispatch(start(props.codeEditorRef))}
                                            >
                                            Start.
                                            </button>
                                            </div>
                                            <div className={'flexItem'}>
                                            <button
                                            className={styles.button + levelComplete ? ' active' : ''}
                                            aria-label="Restart"
                                            onClick={() => dispatch(restart(props.codeEditorRef))}
                                            >
                                            Restart.
                                            </button>
                                            </div>
                                            <div className={'flexItem'}>
                                            <button
                                            className={styles.button + levelComplete ? ' active' : ''}
                                            aria-label="Toggle"
                                            onClick={() => dispatch(updateCanvasToggle(!canvasToggle))}
                                            >
                                            Toggle
                                            </button>
                                            </div>
                                            </div>
            {/*<div className={"metrics"}>
            <div className={"flexItem first"}>Lives: {remainingLives}</div>
            <div className={'flexItem'}>
                x: {Math.round(pongTracker.balls[0].location.x * 100) / 100}
            </div>
            <div className={'flexItem'}>
                y: {Math.round(pongTracker.balls[0].location.y * 100) / 100}
            </div>
            </div>*/}
        </>
)};

export default UIControls;