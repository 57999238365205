import React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/material/Icon/';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { SAILOR_BLUE, MINT_GREEN, EDITOR_HEIGHT, NAV_BAR_DECORATION_HEIGHT } from '../../constants/constants';
import { useAppSelector} from "../../app/hooks";
import { User } from "@auth0/auth0-react";
import './theme/LevelCompletion.css';
import vimPongLevelSummary from "../../data/levels/vim/pong/levelSummaryList.json";
import {selectLevelHistory} from "../user/userSlice";
import {LevelSummary} from "../../globalTypes";
import {selectLevel} from "../scene/managerSlice";

interface LevelCompletionProps {
    user: User | undefined
    isAuthenticated: boolean
    isLoading: boolean
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `2px dotted #eaeaea`,
    color: SAILOR_BLUE,
    borderTop: `0px`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '.MuiAccordionSummary-root': {
        padding:0,
    }

}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, 0)',
    borderTop: '0px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: 0,
    },
    '& .MuiAccordionSummary-root': {
        padding: 5,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    backgroundColor: MINT_GREEN,
    color: SAILOR_BLUE,
    height: EDITOR_HEIGHT + NAV_BAR_DECORATION_HEIGHT,
    borderTop: '2px dotted #fff',
}));

export const LevelCompletion = (props: LevelCompletionProps) => {

    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const userLevelHistory = useAppSelector(selectLevelHistory);
    const level = useAppSelector(selectLevel);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const levelSummaryList = vimPongLevelSummary as LevelSummary[];

    /*const handleLevelSummary = (levelIdentifier :number) => {
        return dispatch({type:'levelSummary/selectLevelSummaryItem', payload:levelIdentifier})
    }*/

    return (
        <div id="level-completion-list">
            <div className={'maps'}>
                {levelSummaryList.map((item) => (
                    <div key={item.levelIdentity} id={'levelCompletion-'+item.levelIdentity}>
                        {/* could locally set state on click, but probably better to do that through redux, or not at all.*/}
                        <Accordion expanded={(level.levelNumber === item.levelIdentity) || expanded === /*'panel' + item.levelIdentity*/ 'false'}
                                   className={(level.levelNumber === item.levelIdentity) ? 'activeLevel' : ''}
                                   onChange={handleChange('panel' + item.levelIdentity)}>
                            <AccordionSummary aria-controls={"panel" + item.levelIdentity + "d-content"}
                                              id={"panel" + item.levelIdentity + "d-header"}>
                                {userLevelHistory.completed.includes(item.levelIdentity) ?
                                    <Typography className={"levelCompletionCheck"}
                                                sx={{flexShrink: 0 }}>
                                        ☑
                                        <span className={'item-level-name'}>{item.levelName}</span>
                                    </Typography>
                                    :
                                    <Typography className={"levelCompletionCheck"}
                                                sx={{flexShrink: 0 }}>
                                        <span className={'checkbox'}>☐</span>
                                        <span className={'item-level-name'}>{item.levelName}</span>
                                    </Typography>
                                }
                            </AccordionSummary>
                            <AccordionDetails className={"levelSummaryInstructionList"}>
                                <div className={'accordionEntryWrapper'}>
                                    <ul className={'levelInstructionList'}>
                                    {item.levelInstructions.map( (instruction, index) => {
                                        return (
                                            <li key={index} className={"instruction"}>
                                                <span> {instruction} </span>
                                            </li>)
                                    })}
                                    </ul>
                                    <div className={'levelText'}>
                                        <p>{item.levelSummary}</p>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LevelCompletion
