import {GridLocation, GridLocationChar, Point, RenderEditorFeature} from "../../../globalTypes";
import {getPointFromGridLocationCenter} from "../../../common/geometry";
import {EDITOR_TOTAL_LEFT_OFFSET, EDITOR_TOTAL_TOP_OFFSET} from "../../../constants/constants";
import {EditorView} from "@codemirror/view";

export const featuresToGridLocations = (features :RenderEditorFeature[]) :GridLocation[] => {
    let gridLocations :GridLocation[] = [];
    for (const feature of features) {
        for(let i = feature.startIndex; i <= feature.endIndex; i++ ){
            gridLocations.push({line:feature.line, char:i});
        }
    }
    return gridLocations;
}

export const getDocPositionFromGridLocation = (viewRef: React.MutableRefObject<EditorView | null>, gridLocation: GridLocation | GridLocationChar) => {

    //!SYNC Importance: this reference frame begins from the top left corner of the editor.
    const relativeToEditorTopLeftCornerPoint = getPointFromGridLocationCenter(gridLocation);
    //!SYNC Importance: add EDITOR offset constants to get correct docPosition
    const absoluteScreenPosition: Point = {
        x: relativeToEditorTopLeftCornerPoint.x + EDITOR_TOTAL_LEFT_OFFSET + /* see design doc appendix 1*/ -.001,
        y: relativeToEditorTopLeftCornerPoint.y + EDITOR_TOTAL_TOP_OFFSET
    };
    const docPosition = viewRef.current!.posAtCoords(absoluteScreenPosition);
    return docPosition;
}