import React from "react";
import '../../app/theme/Modal.css';
import {LoginForm} from "../../auth/LoginForm";
import {useAppDispatch} from "../../../app/hooks";
import {modal} from "../uiSlice";
import {UserInterfaceModal} from "../../../globalTypes";

interface LoginModalProps {
}

export function LoginModal(props: LoginModalProps) {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(modal(UserInterfaceModal.SIGNUP));
    }

    return (
        <div>
            <LoginForm />
            <div className={'alternateModalLink'}>
                <p>Don’t have an account?</p>
                <button onClick={handleClick} >Sign up</button>
            </div>
        </div>
    )
}

export default LoginModal

