import React from "react";
import {SignUp, SignUpErrors} from "../../globalTypes";
import {Field, Form} from 'react-final-form';
import {useAppDispatch} from "../../app/hooks";
import {postSignUpDetails} from '../../api/apiSlice';
import {emailIsValid} from "./util/common";

export const SignUpForm = () => {

    const dispatch = useAppDispatch();

    const onSubmit = async (values: SignUp) => {

        console.log('on submit');
        let token = await dispatch(postSignUpDetails(values));
        window.alert(JSON.stringify(token));
    }

    return(
        <Form
            onSubmit={onSubmit}
            validate={(values: SignUp) => {
                const errors = {} as SignUpErrors
                if (!values.email) {
                    errors.email = 'Required';
                }
                if(!emailIsValid(values.email)){
                    errors.email = 'Invalid email';
                }
                if (!values.userName) {
                    errors.userName = 'Required';
                }
                if (!values.password) {
                    errors.password = 'Required';
                }
                if(values.password && values.password.length < 8) {
                    errors.password = 'Password must be more than 8 characters';
                }
                if(values.password !== values.confirmPassword) {
                    errors.confirmPassword = 'Passwords must match';
                }
                if(values.dateOfBirthCaptcha){
                    errors.dateOfBirthCaptcha = 'Ineligible date of birth';
                }
                return errors
            }}

            render={({handleSubmit, form, submitting, pristine, values}) => (
                <>
                    <div className={"modalFormHeader"}>
                        <h1>Sign Up</h1>
                    </div>
                    <form onSubmit={handleSubmit} className={"signUpForm form"}>
                        <Field name="userName">
                            {({ input, meta }) => (
                                <div className={'userNameField'}>
                                    <label>Username</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="text" placeholder="Username" />
                                </div>
                            )}
                        </Field>
                        <Field name="email">
                            {({input, meta}) => (
                                <div className={'emailField'}>
                                    <label>Email</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="text" placeholder="Email"/>
                                </div>
                            )}
                        </Field>
                        <Field name="password">
                            {({input, meta}) => (
                                <div className={'passwordField'}>
                                    <label>Password</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="password" placeholder="Password"/>
                                </div>
                            )}
                        </Field>
                        <Field name="confirmPassword">
                            {({input, meta}) => (
                                <div className={'confirmPasswordField'}>
                                    <label>Confirm</label>
                                    {meta.error && meta.touched && <span className={"formError"}>{meta.error}</span>}
                                    <input {...input} type="password" placeholder="Confirm Password"/>
                                </div>
                            )}
                        </Field>
                        <div className="buttons">
                            <button className={"signUp submitButton"} type="submit" disabled={submitting}>
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => form.reset()}
                                disabled={submitting || pristine}
                            >
                                Reset
                            </button>
                        </div>
                    </form>
                </>
            )}
        />
    )
}
