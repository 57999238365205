import React, {useEffect} from 'react';
import './App.css';
import ActiveScreen from "./features/app/ActiveScreen";
import {AUTH0_DOMAIN_DEV, /*AUTH0_DOMAIN_DEV*/ HOMESCREEN} from "./constants/constants";
import {UserInterfaceModal} from "../src/globalTypes";
import {selectScreen, selectModal} from "./features/app/uiSlice";
import {useAppSelector} from "./app/hooks";
//import './assets/css/nes.min.css';
import {THEME} from "./features/app/theme/theme";
import {ThemeProvider} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "./features/app/Header";
import ModalRenderer from "./features/app/modals/ModalRenderer";
import Footer from "./features/app/Footer";
import SecondaryPanel from "./features/app/SecondaryPanel";
import SecondaryHeaderPanel from "./features/app/SecondaryHeaderPanel";
import {useAppDispatch} from "../src/app/hooks";

const App = () => {

    //const dispatch = useAppDispatch();
    const activeScreen = useAppSelector(selectScreen);
    const modalStatus = useAppSelector(selectModal);
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    /* useEffect(() => {

        const getUserMetadata = async () => {

            console.log();
            if(user === undefined) return

            const domain = AUTH0_DOMAIN_DEV;

            try {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope: "openid profile email read:current_user read:users update:users update:users_app_metadata update:current_user_metadata create:users_app_metadata read:users_app_metadata"
                });

                const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

                dispatch({type:"user/accessToken", payload:accessToken})

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const { user_metadata, client_metadata, app_metadata } = await metadataResponse.json();
                console.log({app_metadata});
                console.log({user_metadata});
                console.log({client_metadata});



                dispatch({type:"user/userMetaData", payload:user_metadata});
            } catch (e) {
                console.log(e.message);
            }
        };

        getUserMetadata().then( (r) => {
            console.log(r);
        })

    }, [getAccessTokenSilently, user?.sub, dispatch, user]);

    useEffect(() => {
        dispatch({type:"user/isAuthenticated", payload: isAuthenticated});
        dispatch({type:"user/userDetails", payload: user});
        dispatch({type:"user/isLoading", payload: isLoading});

        let token = null;
        const domain = AUTH0_DOMAIN_DEV;

        (async function iife() {
            token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            })
        })()

        dispatch({type:"user/accessToken", payload: token});
    },[user, isAuthenticated, isLoading, dispatch, getAccessTokenSilently])*/

    return (
        <ThemeProvider theme={THEME}>
            <div className="App">
                <div className={"top"}>
                    <Header user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
                    <SecondaryHeaderPanel user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
                </div>
                <div className={"mainAppWrapper"}>
                    <ActiveScreen user={user} active={activeScreen === HOMESCREEN}/>
                    <SecondaryPanel user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
                </div>
                <Footer user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
            </div>
            { modalStatus === UserInterfaceModal.NONE ?
                null
            :
                <ModalRenderer isLoading={false}/> }
        </ThemeProvider>
    );
}

export default App;
