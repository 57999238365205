/*
EDITOR CONSTANTS
 */
export const LINE_HEIGHT :number = 18;
export const ROWS: number = 24;
export const COL_PER_ROW: number = 3/2;
export const COLS: number = ROWS*COL_PER_ROW;

//18 * 24 = 432px
export const EDITOR_HEIGHT: number = LINE_HEIGHT * 24;
//24 * 18 * 3 / 2 = 648px
export const EDITOR_WIDTH: number = COLS * 18;
export const NAV_BAR_DECORATION_HEIGHT: number = 50;

export const DEFAULT_PADDLE_WIDTH = 50;
export const HOMESCREEN = 'HOMESCREEN';
export const HELPSCREEN = 'HELPSCREEN';
export const EDITORSCREEN = 'EDITORSCREEN';
export const LOGINSCREEN = 'LOGINSCREEN';

//colors
export const SAILOR_BLUE = '#00203F';
export const MINT_GREEN = '#ADEFD1';
export const WHITE = '#FFFFFF';
export const STRING_COLOR = '#FFFFFF';

//gruvbox colors
export const GB_BACKGROUND_GRAY = '#282828';
export const GB_RED = '#CC241D';
export const GB_GREEN = '#98971A';
export const GB_STRAW_YELLOW = '#D79921';
export const GB_BLUE = '#458588';
export const GB_PURPLE = '#B16286';
export const GB_AQUA = '#689D6A';
export const GB_LIGHT_GRAY = '#A89984';
export const GB_DARK_GRAY = '#928374';
export const GB_PASTEL_RED = '#FB4934';
export const GB_PEA_GREEN = '#B8BB26';
export const GB_YELLOW = '#FABD2F';
export const GB_BEE_YELLOW = '#FABD2F';
export const GB_TEAL = '#83A598';
export const GB_VIOLET = '#D3969B';
export const GB_MONEY_GREEN = '#8EC07C';
export const GB_CREAM = '#EBDBB2';
export const GB_BLACK = '#1D2021';
export const GB_DARK_ORANGE = '#D65D0E';
export const GB_ORANGE = '#FE8019';

export const COMMENT_COLOR = GB_STRAW_YELLOW;
export const VARIABLE_COLOR = GB_ORANGE;
export const FUNCTION_NAME_COLOR = GB_CREAM;
export const SYNTAX_NAME_COLOR = GB_PASTEL_RED;
export const INTEGER_COLOR = GB_MONEY_GREEN;

export const BALL_RADIUS = 5;
export const DELTA_TIME = 150;
export const HEADER_HEIGHT = 100;
export const BOTTOM_OF_HEADER_TO_EDITOR_SCREEN_HEIGHT = 100;
export const EDITOR_TOP_HTML_OFFSET = HEADER_HEIGHT + BOTTOM_OF_HEADER_TO_EDITOR_SCREEN_HEIGHT
export const EDITOR_TOTAL_TOP_OFFSET = EDITOR_TOP_HTML_OFFSET;
export const EDITOR_LEFT_HTML_OFFSET = 40;
export const EDITOR_GUTTER_WIDTH = 24;
export const EDITOR_TOTAL_LEFT_OFFSET = EDITOR_LEFT_HTML_OFFSET + EDITOR_GUTTER_WIDTH;
export const DEFAULT_LINE_OFFSET = 0;
export const DEFAULT_CHAR_OFFSET = 0;
export const MAX_CHARACTERS_IN_VIEWPORT = 80;
export const CHARACTER_WIDTH = (EDITOR_WIDTH - EDITOR_GUTTER_WIDTH) / MAX_CHARACTERS_IN_VIEWPORT;

/*
CONTENT CONSTANTS
 */
export const RENDER_EDITOR_CONTENT = "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n" +
        "                                                                                \n";
/*
API/FETCHING CONSTANTS
 */

export const API_EXPVI_URL = "https://localhost:8029/";
export const API_LEVEL_URL = "https://www.vimarcade.com/json/levels/pong/";
export const AUTH0_DOMAIN_DEV = "";
export const AUTH0_CLIENTID = "";
export const LAST_LEVEL = 21;