import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../../app/store';
import {
    GameLevel,
    GameTracker,
    GridLocationChar,
    Level,
    LevelStatus,
    PongLevel,
    SessionStatus, TextTask, TextTaskStatus
} from '../../globalTypes';
import {defaultState} from '../../constants/defaultStates';
import {
    updatePongState,
    resetPongState,
} from './pong/pongSlice';
import {EditorView} from "@codemirror/view";
import React from "react";
import {
    asyncRepaintEditor,
    editorFeatureCheck,
    shouldResetSecondaryFx, shouldResetStyleMatched
} from '../cm6Connect/renderEditorSlice';
import {DELTA_TIME} from "../../constants/constants";
import {calculateOffsets} from "../cm6Connect/cM6EditorSlice";
import vimPongLevel1Json from "../../data/levels/vim/pong/level-1.json";
import vimPongLevel2Json from "../../data/levels/vim/pong/level-2.json";
import {fetchAndSetNextLevel, setUserLevelMetadata} from "../../api/apiSlice";
import {
    gridLocationCharsFromTextTasks,
} from "../../common/geometry";
import {verticalScroll} from "../../common/scroll";

export interface ManagerState {
    level: GameLevel
    nextLevel: Level
    levelStatus: LevelStatus
    sessionStatus: SessionStatus
    canvasType: 'pong' | 'spaceInvaders' | 'brickBreaker'
    gameTracker: GameTracker
    canvasToggle: boolean
    levelComplete: false
    remainingLives: number
    deltaTime: number
}

const initialState: ManagerState = {
    level: vimPongLevel1Json as PongLevel,
    nextLevel: vimPongLevel2Json as PongLevel,
    levelStatus: LevelStatus.IDLE,
    sessionStatus: SessionStatus.IDLE,
    gameTracker: defaultState.pong,
    canvasType: 'pong',
    canvasToggle: true,
    levelComplete: false,
    remainingLives: 3,
    deltaTime: DELTA_TIME,

};

export const managerSlice = createSlice({
    name: 'manager',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeCanvasType: (state, action: PayloadAction<'pong' | 'spaceInvaders' | 'brickBreaker'>) => {
            state.canvasType = action.payload
        },
        level: (state, action: PayloadAction<GameLevel>) => {
            state.level = action.payload;
        },
        levelStatus: (state, action: PayloadAction<LevelStatus>) => {
            state.levelStatus = action.payload;
        },
        sessionStatus: (state, action: PayloadAction<LevelStatus>) => {
            state.levelStatus = action.payload;
        },
        textTaskMatched: (state, action: PayloadAction<TextTask["identity"]>) => {
            state.level.textTasks = state.level.textTasks.map( (task) => {
                if(task.identity === action.payload){
                    return { ...task, status: TextTaskStatus.COMPLETE }
                }
                return task
            });
        },
        resetTextTaskMatched: (state) => {
            state.level.textTasks = state.level.textTasks.map( (task) => {
                return { ...task, status: TextTaskStatus.NONE }
            });
        },
        updateCanvasToggle: (state, action: PayloadAction<boolean>) => {
            state.canvasToggle = action.payload;
        },
        nextLevel: (state, action: PayloadAction<Level>) => {
            state.nextLevel = action.payload;
        },
        decrementRemainingLives: (state, action: PayloadAction<number>) => {
            state.remainingLives = state.remainingLives - action.payload;
        },
        incrementRemainingLives: (state, action: PayloadAction<number>) => {
            state.remainingLives = state.remainingLives + action.payload;
        },
        setRemainingLives: (state, action: PayloadAction<number>) => {
            state.remainingLives = action.payload;
        },
        setDeltaTime: (state, action: PayloadAction<number>) => {
            state.deltaTime = action.payload;
        }
    },
});

export const getInitialState = () => {
    return initialState;
}

export const {
    level,
    changeCanvasType,
    updateCanvasToggle,
    sessionStatus,
    levelStatus,
    decrementRemainingLives,
    incrementRemainingLives,
    nextLevel,
    textTaskMatched,
    setDeltaTime
} = managerSlice.actions;

export const selectCanvasType = (state: RootState) => state.manager.canvasType;
export const selectGameTracker = (state: RootState) => state.manager.gameTracker;
export const selectCanvasToggle = (state: RootState) => state.manager.canvasToggle;
export const selectLevel = (state: RootState) => state.manager.level;
//export const selectNextLevel = (state: RootState) => state.manager.nextLevel;
export const selectDeltaTime = (state: RootState) => state.manager.deltaTime;
export const selectLevelComplete = (state: RootState) => state.manager.levelComplete;
export const selectRemainingLives = (state: RootState) => state.manager.remainingLives;


export const updateManagerState = (viewport :React.MutableRefObject<EditorView | null>) : AppThunk => (dispatch, getState) => {
    const state = getState();

    const textTasks :TextTask[] = state.manager.level.textTasks;
    //we need to change gridLocationCharsFromTextTasks to support grouping by task, so ghost fx
    //fire for each task and not all at once, at the end.
    const indexedChars :GridLocationChar[][] = gridLocationCharsFromTextTasks(textTasks);
    for(let i = 0; i < textTasks.length; i++) {

        if (textTasks[i].status !== TextTaskStatus.COMPLETE){
            dispatch(editorFeatureCheck(indexedChars[i], textTasks[i].identity));
        }
    }
}

export const editorDetailsUpdated = (editorStateUpdate :any, renderEditorViewRef :React.MutableRefObject<EditorView | null>, codeEditorViewRef :React.MutableRefObject<EditorView | null>): AppThunk => (dispatch, getState) => {
    const state = getState();

    switch (state.manager.levelStatus){
        case LevelStatus.IDLE: {
            break;
        }
        case LevelStatus.COLLISION: {
            break;
        }
        case LevelStatus.RESTART_MODE: {
            break;
        }
        case LevelStatus.LOADING: {
            break;
        }
        case LevelStatus.ACTIVE: {

            dispatch(asyncRepaintEditor(renderEditorViewRef));

            if (codeEditorViewRef?.current?.contentDOM?.getBoundingClientRect() === undefined) return;

            //copy to prevent read-only redux-toolkit errors.
            const {x, y, height, width} = codeEditorViewRef?.current?.contentDOM?.getBoundingClientRect();

            const boundingRect = {x: x, y: y, width: width, height: height};

            dispatch(calculateOffsets(boundingRect));

            dispatch({type: 'cm6Editor/gridMatrix', payload: editorStateUpdate})

            //used for debugging, not game mechanics.
            if (state.manager.canvasToggle === false) {
                return
            }

            dispatch(updateManagerState(codeEditorViewRef));

            switch (state.manager.canvasType) {
                case 'pong':

                    dispatch(updatePongState(codeEditorViewRef));

                    break;

                case 'spaceInvaders':

                    break;

                case 'brickBreaker':

                    break;

                default:
                    break;
            }//end canvas type
            break;//break from LevelStatus.ACTIVE
        }
    }
}

export const reset = (editorViewRef :React.MutableRefObject<EditorView | null>): AppThunk => (dispatch, getState) => {
    const state = getState();
    switch (state.manager.canvasType) {
        case 'pong':

            dispatch(resetPongState(defaultState.pong));

            break;

        case 'spaceInvaders':

            //dispatch(resetSpaceInvaders(defaultState.spaceInvaders));

            break;

        case 'brickBreaker':

            //dispatch(resetSpaceBrickBreaker(defaultState.brickBreaker));

            break;

        default:
            break;
    }

}

export const focusEditor = (editorViewRef :React.MutableRefObject<EditorView | null>) => {
    //none of this works right... does it need to be invoked through cm6?
    if(editorViewRef.current !== null) {
        editorViewRef.current.focus();
    }
}

export const scrollToLevelInstructionSet = (level :Level) => {

    let levelDiv = document.getElementById('levelCompletion-' + level.levelNumber);

    if(levelDiv !== null){
        setTimeout(() => {
            levelDiv = document.getElementById('levelCompletion-' + level.levelNumber);
            if(levelDiv !== null) {
                verticalScroll(levelDiv);
            }
        },500);
    }

}

export const proceed = (prevLevel :Level): AppThunk => (dispatch, getState) => {
    const state = getState();

    const loadedLevel :Level = state.manager.nextLevel;

    if(!state.user.levelHistory.completed.includes(prevLevel.levelNumber)) {
        dispatch({type: 'user/completedLevel', payload: prevLevel.levelNumber});
    }
    dispatch({type: 'user/lastAttemptedLevel', payload: loadedLevel})
    dispatch(setUserLevelMetadata());
    dispatch({type:'manager/level', payload: loadedLevel});
    dispatch(fetchAndSetNextLevel(loadedLevel.levelNumber + 1));

    //visual sidefx, do these need special handling?
    scrollToLevelInstructionSet(loadedLevel);

    //previous data to clear.
    dispatch({type:'manager/resetTextTaskMatched'});

    switch (loadedLevel.canvas) {
        case 'pong':
            const pongLevel = loadedLevel as PongLevel;
            dispatch({type: 'pong/balls', payload: pongLevel.balls});
            dispatch(setDeltaTime(pongLevel.deltaTime));
            //dispatch(resetPongState(defaultState.pong));
            //dispatch({type:'pong/targetLocation', payload: loadedLevel.targetLocation});

            break;

        case 'spaceInvaders':

            //dispatch(resetSpaceInvaders(defaultState.spaceInvaders));

            break;

        case 'brickBreaker':

            //dispatch(resetSpaceBrickBreaker(defaultState.brickBreaker));

            break;

        default:
            break;
    }

    //updates to EditorView content need to be async.
    setTimeout(() => {
        dispatch({type:'cm6Editor/content', payload: loadedLevel.initialEditorString});
    }, 2);
};

export const proceedButton = (editorViewRef :React.MutableRefObject<EditorView | null>, prevLevel :Level): AppThunk => (dispatch, getState) => {
    focusEditor(editorViewRef);
    dispatch(proceed(prevLevel));
}

export const start = (editorViewRef :React.MutableRefObject<EditorView | null>): AppThunk => (dispatch, getState) => {

    const state = getState();
    const currentLevel :GameLevel = state.manager.level;

    dispatch({type:"manager/levelStatus", payload: LevelStatus.ACTIVE});
    dispatch({type:"manager/sessionStatus", payload: SessionStatus.ACTIVE});

    scrollToLevelInstructionSet(currentLevel);
    focusEditor(editorViewRef);
}

export const restart = (editorViewRef :React.MutableRefObject<EditorView | null>): AppThunk => (dispatch, getState) => {
    const state = getState();
    const currentLevel :GameLevel = state.manager.level;

    switch (currentLevel.canvas) {
        case 'pong':

            const currentPongLevel = currentLevel as PongLevel;

            dispatch({type:"pong/balls", payload: currentPongLevel.balls});
            dispatch({type:"manager/levelStatus", payload: LevelStatus.ACTIVE});
            dispatch({type:"manager/sessionStatus", payload: SessionStatus.ACTIVE});            
            break;

        case 'spaceInvaders':

            //dispatch(resetSpaceInvaders(defaultState.spaceInvaders));

            break;

        case 'brickBreaker':

            //dispatch(resetSpaceBrickBreaker(defaultState.brickBreaker));

            break;

        default:
            break;
    }

    //updates to EditorView content need to be async.
    setTimeout(() => {
        dispatch({type:'cm6Editor/content', payload:''});
        dispatch(shouldResetSecondaryFx());
        dispatch({type:'manager/resetTextTaskMatched'});
        dispatch(shouldResetStyleMatched());
    },2);

    setTimeout(() => {
        dispatch({type:'cm6Editor/content', payload: currentLevel.initialEditorString});
    }, 5);

    scrollToLevelInstructionSet(currentLevel);
    focusEditor(editorViewRef);

};


export default managerSlice.reducer;
