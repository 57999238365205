import React from 'react';
import { User } from '@auth0/auth0-react';
import styles from '../menu/UserMenu.module.css';
//import LogoutButton from "../app/common/LogoutButton";
import HeaderProfile from "./HeaderProfile";
import HeaderDropdownMenu from "../menu/HeaderDropdownMenu";

interface VimUserMenuProps {
    user: User | undefined
}

export function VimUserHeader(props: VimUserMenuProps) {


    return (
        <div className={styles.vimUserMenu}>
            <HeaderProfile user={props.user}/>
            <div className={'menu-login-wrapper'}>
                <HeaderDropdownMenu user={props.user}/>
            </div>
        </div>
    );
}
