import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {defaultState} from "../../constants/defaultStates";
import {
    GridBlock,
    GridLocation,
    GridLocationChar,
    Point,
    RenderEditorFeature,
    RenderEditorTracker
} from "../../globalTypes";
import {primaryStateEffects, /*secondaryStateEffects,*/ tertiaryStateEffects} from "../scene/pong/pongSlice";
import React from "react";
import {EditorView} from "@codemirror/view";
import {StateEffect} from "@codemirror/state";
import {/*addUnderline,*/ highlightLine, identityEffect} from "./customPlugins/underlineField";
//import {getPointFromGridLocationCenter} from "../../common/geometry";
import {EDITOR_TOTAL_LEFT_OFFSET, EDITOR_TOTAL_TOP_OFFSET} from "../../constants/constants";
//import {addStyleTargetLocation} from "./customPlugins/targetLocation";
//import {addStyleEditorFeature} from "./customPlugins/editorFeature";
import {featuresToGridLocations /*,getDocPositionFromGridLocation*/} from "./utility/common";
import {textTaskMatched} from "../scene/managerSlice";

const initialState: RenderEditorTracker = defaultState.renderEditorTracker;

export const asyncRepaintEditor = createAsyncThunk(
    'renderEditor/asyncRepaintEditor',
    async (viewRef: React.MutableRefObject<EditorView | null>, thunkAPI) => {
        const preTimeoutState = thunkAPI.getState() as RootState

        return await new Promise(resolve => {
            setTimeout(() => {

                if (viewRef.current === null) return

                const state: RootState = thunkAPI.getState() as RootState

                /*const combinedPrimaryEffects = state[state.manager.canvasType].primaryStateEffects.flatMap( effect => {
                    return effect.locations
                });

                const primaryFx: StateEffect<unknown>[] = combinedPrimaryEffects.map( (gridLocation :GridLocation) => {

                    const docPosition = getDocPositionFromGridLocation(viewRef, gridLocation);

                    if (docPosition === null) return identityEffect.of({from: 0, to: 1});

                    return addStyleTargetLocation.of({from: docPosition, to: docPosition + 1 })

                    //return addGrayHighlight.of({from: docPosition, to: docPosition + 1 })

                });*/

                const secondaryFx: StateEffect<unknown>[] = state[state.manager.canvasType].secondaryStateEffects.map(({x, y, type}) => {

                    /*!SYNC Importance: the parameters for this reference frame begin from the top left corner of the editor,
                    so we must adjust for wherever the editor is placed with CSS. */
                    const absoluteScreenPosition :Point = { x: x + EDITOR_TOTAL_LEFT_OFFSET, y: y + EDITOR_TOTAL_TOP_OFFSET};
                    //!SYNC Importance: use EDITOR offset constants (EDITOR_TOTAL_LEFT_OFFSET) to get correct docPosition
                    const docPosition = viewRef.current!.posAtCoords(absoluteScreenPosition);

                    if (docPosition === null) return identityEffect.of({from: absoluteScreenPosition.x, to: absoluteScreenPosition.y});

                    let state = thunkAPI.getState() as RootState;
                    if(state.renderEditor.shouldResetSecondaryFx === true) return identityEffect.of({from: absoluteScreenPosition.x, to: absoluteScreenPosition.y});
                    switch (type) {
                        //case "addUnderline":
                        // return addUnderline.of({from: docPosition, to: docPosition + 1});
                       //     break;
                        case "highlightLine":
                            const lineNumber = viewRef.current!.state.doc.lineAt(docPosition);

                            return highlightLine.of({from: lineNumber.from, to: lineNumber.from});
                        default:
                            return identityEffect.of({from: docPosition, to: docPosition + 1});
                    }
                })

                /*const combinedTertiaryEffects = state[state.manager.canvasType].tertiaryStateEffects.flatMap( effect => {
                    return effect.locations
                });

                const tertiaryFx: StateEffect<unknown>[] = combinedTertiaryEffects.map( (gridLocation :GridLocation) => {


                    //!SYNC Importance: this reference frame begins from the top left corner of the editor.
                    const relativeToEditorTopLeftCornerPoint = getPointFromGridLocationCenter(gridLocation);
                    //!SYNC Importance: add EDITOR offset constants to get correct docPosition
                    const absoluteScreenPosition :Point = { x: relativeToEditorTopLeftCornerPoint.x + EDITOR_TOTAL_LEFT_OFFSET, y: relativeToEditorTopLeftCornerPoint.y + EDITOR_TOTAL_TOP_OFFSET};
                    const docPosition = viewRef.current!.posAtCoords(absoluteScreenPosition);

                    if (docPosition === null) return identityEffect.of({from: 0, to: 1});

                    return addStyleEditorFeature.of({from: docPosition, to: docPosition + 1 })

                    //return addGrayHighlight.of({from: docPosition, to: docPosition + 1 })

                }); */

                viewRef.current.dispatch({
                    effects: [/*resetfx, ...resetfx??  ...primaryFx, */...secondaryFx, /*...tertiaryFx*/]
                })

            }, preTimeoutState.manager.deltaTime / 2);
        })
    }
)

export const renderEditorSlice = createSlice({
    name: 'renderEditor',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        content: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
        styleMatchedText: (state, action: PayloadAction<GridLocationChar[]>) => {
            state.styleMatchedText = action.payload;
        },
        shouldResetStyleMatched: (state, action: PayloadAction<boolean>) => {
            state.shouldResetStyleMatched = action.payload;
        },
        shouldResetSecondaryFx: (state, action:PayloadAction<boolean>) => {
            state.shouldResetSecondaryFx = action.payload;
        }
    },
});

export const editorFeatureCheck = (textGoal :GridLocationChar[], textTaskIndex :number) : AppThunk => (dispatch, getState) => {
    if(textGoal.length === 0) return

    const state = getState();
    const gridMatrix :GridBlock[][] = state.cm6Editor.gridMatrix;

    // The problem now is, there is no grouping of textTasks in this loop. So it's either all or
    // nothing.

    for(const character of textGoal) {
        //null checks in the refresh editor to blank state.
        if(gridMatrix[character.line]                       === undefined) return
        if(gridMatrix[character.line][character.char]       === undefined) return
        if(gridMatrix[character.line][character.char].entry === undefined) return

        if (gridMatrix[character.line][character.char].entry !== character.item) {
            return;
        }
    }
    dispatch(styleMatchedText(textGoal, textTaskIndex));
}

export const styleMatchedText = (textGoals: GridLocationChar[], textTaskIndex :number ): AppThunk => (dispatch, getState) => {
    dispatch({type:'renderEditor/styleMatchedText', payload: textGoals});
    dispatch(textTaskMatched(textTaskIndex));
}

export const renderHighlightTargetLocation = (squares: GridLocation[]): AppThunk => (dispatch, getState) => {
    dispatch(primaryStateEffects([{locations: squares, type: "illuminateTargetLocation"}]));
}

export const renderEditorFeatures = (features: RenderEditorFeature[]): AppThunk => (dispatch, getState) => {
    let squares = featuresToGridLocations(features);
    dispatch(tertiaryStateEffects([{locations: squares, type: "illuminateCopyPasteText"}]));
}
export const shouldResetSecondaryFx = (): AppThunk => (dispatch, getState) => {
    dispatch({type:'pong/secondaryStateEffects', payload: []});
    dispatch({type:'renderEditor/shouldResetSecondaryFx', payload: true});
}

export const shouldResetStyleMatched = (): AppThunk => (dispatch, getState) => {
    dispatch({type:'renderEditor/shouldResetStyleMatched', payload: true});
}

export const selectRenderContent = (state: RootState) => state.renderEditor.content;
export const selectshouldResetSecondaryFx = (state: RootState) => state.renderEditor.shouldResetSecondaryFx;
export const selectshouldResetStyleMatched = (state: RootState) => state.renderEditor.shouldResetStyleMatched;
export const selectStyleMatchedText = (state: RootState) => state.renderEditor.styleMatchedText;

export default renderEditorSlice.reducer;
