import React from "react";
import { User } from "@auth0/auth0-react";
import './Profile.css';

interface HeaderProfileProps {
    user: User | undefined
}

const HeaderProfile = (props: HeaderProfileProps) => {

    if(props.user === undefined){
        return <div>No User ...</div>
    }

    return (
        <div className={'left-profile-wrapper'}>
            <div className={'header-profile-img-wrapper'}>
                <img className={'header-profile-img'} src={props.user.picture} alt={props.user.name} />
            </div>
            {/* <h2 className={'profile-name'}>{props.user.name}</h2>
            <p className={'header-profile-email'}>{props.user.email}</p>*/}
        </div>
    );
};

export default HeaderProfile;