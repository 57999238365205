import React from "react";
import { User} from "@auth0/auth0-react";
import LogoutButton from "../app/common/LogoutButton";

interface HeaderDropdownMenuProps {
    user: User | undefined
}

const HeaderDropdownMenu = (props: HeaderDropdownMenuProps) => {

    if(props.user === undefined){
        return <div>No User ...</div>
    }
    const handleClick = () => {
        console.log('handle click in user menu');
    }

    return (
        <div className={'header-menu-wrapper'}>
            <LogoutButton handleClickCallBack={handleClick}/>
        </div>
    );
};

export default HeaderDropdownMenu;