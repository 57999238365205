import React from "react";
import { User } from "@auth0/auth0-react";
import './theme/Header.css';

interface HeaderProps {
    user: User | undefined
    isAuthenticated: boolean
    isLoading: boolean
}

export function Header(props: HeaderProps) {
    return (
        <header className="App-header">
            <div className="share">
                <h1 className={"main-name-heading"}>
                    Vim Arcade
                </h1>

                <p className={"cursive tagline"}>
                    {/*<a target="_blank" rel="noreferrer" href="https://dadsquadmusic.com">A dadsquad product.</a>*/}
                </p>
            </div>
        </header>
    )
}

export default Header
