import React from "react"
import { CM6Editor } from "../cm6Connect/CM6Editor"
import { GameCanvas } from "./GameCanvas"
import { useAppSelector } from '../../app/hooks';
import {
    selectCanvasType,
    selectGameTracker,
    selectLevel
} from './managerSlice';
import {selectContent} from "../cm6Connect/cM6EditorSlice"
import {
    selectshouldResetStyleMatched,
    selectRenderContent,
    selectshouldResetSecondaryFx,
    selectStyleMatchedText
} from "../cm6Connect/renderEditorSlice";

import './main.css';

interface ManagerProps {
}

const Manager = (props :ManagerProps) => {
    //use redux.
    const gameTracker = useAppSelector(selectGameTracker);
    const canvasType = useAppSelector(selectCanvasType);
    const content = useAppSelector(selectContent);
    const renderContent = useAppSelector(selectRenderContent);
    const shouldResetSecondaryFx = useAppSelector(selectshouldResetSecondaryFx);
    const shouldResetStyleMatched = useAppSelector(selectshouldResetStyleMatched);
    const styleMatchedText = useAppSelector(selectStyleMatchedText);
    const level = useAppSelector(selectLevel);


    return (
    <div className={'mainWrapper'}>
        <div className={'canvasWrapper'}>
            <GameCanvas canvasTracker={gameTracker}
                        canvasType={canvasType} />
            <CM6Editor content={content}
                       renderContent={renderContent}
                       shouldResetSecondaryFx={shouldResetSecondaryFx}
                       shouldResetStyleMatched={shouldResetStyleMatched}
                       styleMatchedText={styleMatchedText}
                       targetLocation={level.targetLocation}
                       renderEditorFeatures={level.renderEditorFeatures}
            />
        </div>

    </div>
    )

}
export default Manager 
