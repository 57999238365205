import { StateEffect, StateField } from "@codemirror/state";
import {Decoration, DecorationSet, EditorView} from "@codemirror/view";

export const styleTargetLocationMark = Decoration.mark({
    attributes: {class: "cm-arcade-target-location"}
})
export const addStyleTargetLocation = StateEffect.define<{from: number, to: number}>();
export const resetTargetLocation = StateEffect.define<{}>();
export const identityEffect = StateEffect.define<{from: number, to: number}>();

export const targetLocation = StateField.define<DecorationSet>({
    // Start with an empty set of decorations
    create() { return Decoration.none },
    // This is called whenever the editor updates—it computes the new set
    update(styleTargets :DecorationSet , tr) {
        //highlights = highlights.map(tr.changes)
        for (let e of tr.effects) {

            if (e.is(addStyleTargetLocation)) {
                styleTargets = styleTargets.update({
                    add: [styleTargetLocationMark.range(e.value.from, e.value.to)]
                })
            }

            if (e.is(resetTargetLocation)) {
                styleTargets = styleTargets.update({
                    filter: (() => {return false}),
                })
            }
        }
        return styleTargets
    },
    // Indicate that this field provides a set of decorations
    provide: f => EditorView.decorations.from(f)
})