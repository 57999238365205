import {StateEffect, StateField} from "@codemirror/state";
import {Decoration, DecorationSet, EditorView} from "@codemirror/view";
import {DELTA_TIME} from "../../../constants/constants";

export const styleEditorFeatureMark = Decoration.mark({
    attributes: {class: "cm-arcade-editor-feature"}
})
export const addStyleEditorFeature = StateEffect.define<{from: number, to: number}>();
export const resetStyleEditorFeature = StateEffect.define<{}>();
export const identityEffect = StateEffect.define<{from: number, to: number}>();

export const editorFeature = StateField.define<DecorationSet>({
    // Start with an empty set of decorations
    create() { return Decoration.none },
    // This is called whenever the editor updates—it computes the new set
    update(styleTargets :DecorationSet , tr) {
        //highlights = highlights.map(tr.changes)
        for (let e of tr.effects) {

            if (e.is(addStyleEditorFeature)) {

                styleTargets = styleTargets.update({
                    add: [styleEditorFeatureMark.range(e.value.from, e.value.to)]
                })
            }

            if (e.is(resetStyleEditorFeature)) {

                styleTargets = styleTargets.update({
                    filter: (() => {return false}),
                })
            }
        }
        return styleTargets
    },
    // Indicate that this field provides a set of decorations
    provide: f => EditorView.decorations.from(f)
})

export interface GameState{
    deltaTime: number
}
export const gameStateStateEffectType = StateEffect.define<GameState>();

//check DesignDoc.txt for hints.
export const gameState = StateField.define<GameState>({
    create: () => {
        return {
            deltaTime: DELTA_TIME
        }
    },
    update: (value, transaction) => {
        let newValue = {...value};
        for(const effect of transaction.effects) {
            if (effect.is(gameStateStateEffectType)) {
                let deltaTime = effect.value.deltaTime;
                newValue.deltaTime = deltaTime;
            }
        }
        return newValue;
    },
});
