import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../../app/store';
import {LevelHistory, UserInfoResponse, VimUser} from "../../globalTypes";
//import {User} from "@auth0/auth0-react";


export interface UserState {
    status: 'loggedIn' | 'loading' | 'loggedOut'
    isAuthenticated: boolean
    //userDetails: User | undefined
    vimUserDetails: VimUser | undefined
    levelHistory: LevelHistory
    accessToken: string
}

const initialState: UserState = {
    status: 'loggedOut',
    isAuthenticated: false,
    vimUserDetails: undefined,
    levelHistory: { completed:[], lastAttempted:1 },
    accessToken: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        status: (state, action: PayloadAction<'loggedIn' | 'loading' | 'loggedOut'>) => {
            state.status = action.payload;
        },
        completedLevel:(state, action: PayloadAction<number>) => {
            state.levelHistory.completed.push(action.payload);
        },
        isAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        accessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        /*userDetails: (state, action: PayloadAction<User>) => {
            state.userDetails = action.payload;
        },*/
        userResponseData: (state, action: PayloadAction<UserInfoResponse>) => {
            state.vimUserDetails = action.payload;
        }
    },
});

export const logoutCurrentUser = (): AppThunk => (
    dispatch,
    getState
) => {
    dispatch({type:'user/status', payload:'loggedOut'});

};

export const {status} = userSlice.actions;

export const selectStatus = (state: RootState) => state.user.status;
export const selectLevelHistory = (state: RootState) => state.user.levelHistory;


export default userSlice.reducer;
