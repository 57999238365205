import React, {  CSSProperties } from "react";
import { EDITOR_WIDTH, EDITOR_HEIGHT } from '../../../constants/constants'
import { useAppSelector } from '../../../app/hooks';
import { selectPongTracker } from './pongSlice';
import { DrawPongBall } from './DrawPongBall';

export const Pong = (styles :CSSProperties) => {

  const PongTracker = useAppSelector(selectPongTracker);
  const balls = PongTracker.balls;
//  const paddles = PongTracker.paddles;

    return (
        <svg
        id="aliens-go-home-canvas"
        height={EDITOR_HEIGHT+"px"}
        width={EDITOR_WIDTH+"px"}
        //preserveAspectRatio="xMaxYMax none"
        style={styles}
      >
        {
          balls.map((ball, index) => (
            <DrawPongBall {...ball} key={index} />
          ))
        }

      </svg>
    )

}