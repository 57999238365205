import React, {  CSSProperties } from "react";
import { EDITOR_WIDTH, EDITOR_HEIGHT } from '../../../constants/constants'

export const BrickBreaker = (styles :CSSProperties) => {

    return (
        <svg
        id="aliens-go-home-canvas"
        height={EDITOR_HEIGHT+"px"}
        width={EDITOR_WIDTH+"px"}
        preserveAspectRatio="xMaxYMax none"
        style={styles}
      >
        <circle cx={100} cy={100} r={5} />
      </svg>
    )

}