import {
    BrickBreakerTracker,
    PongTracker,
    SpaceInvadersTracker,
    EditorTracker,
    Level,
    UserInterface,
    UserInterfaceScreen,
    UserInterfaceStatus,
    RenderEditorTracker,
    UserInterfaceModal,
    CookieConsentStatus,
    AuthFormInterface, StripeInterface

} from "../globalTypes";
import {
    DEFAULT_PADDLE_WIDTH,
    EDITOR_WIDTH,
    EDITOR_HEIGHT,
    EDITOR_TOTAL_LEFT_OFFSET,
    EDITOR_TOTAL_TOP_OFFSET,
    ROWS,
    MAX_CHARACTERS_IN_VIEWPORT,
    RENDER_EDITOR_CONTENT,
    DELTA_TIME,
    DEFAULT_LINE_OFFSET,
    DEFAULT_CHAR_OFFSET,
} from "../constants/constants";
import vimPongLevel1Json from "../data/levels/vim/pong/level-1.json";
const vimPongLevel1JsonContent = vimPongLevel1Json.initialEditorString;

export interface DefaultStates {
    renderEditorTracker: RenderEditorTracker
    codeEditorTracker: EditorTracker
    pong: PongTracker
    spaceInvaders: SpaceInvadersTracker
    brickBreaker: BrickBreakerTracker
    level: Level
    ui: UserInterface
    auth: AuthFormInterface
    stripe: StripeInterface
}

const gridMatrix = new Array(ROWS).fill(" ").map( (value, i) => {
    return new Array(MAX_CHARACTERS_IN_VIEWPORT).fill(" ").map((value, j) => {
        //indexed from 1.
        return { line:i, char:j, entry:" ", source: "cm6" }
    })
})

export const defaultState: DefaultStates = {
    renderEditorTracker: {
        focusedStartingPos: {x: 0, y: 0},
        focusLength: 0,
        gridMatrix: gridMatrix,
        shouldResetSecondaryFx: false,
        shouldResetStyleMatched: false,
        styleMatchedText: [],
        initialChange: [{from: 0, insert: ""}],
        deltaTime: DELTA_TIME,
        content: RENDER_EDITOR_CONTENT,
        editorOffsets: {
            editorTop: EDITOR_TOTAL_TOP_OFFSET,
            editorBottom: EDITOR_HEIGHT,
            editorLeft: EDITOR_TOTAL_LEFT_OFFSET,
            editorRight: EDITOR_WIDTH
        },
        offsetLineIndex: DEFAULT_LINE_OFFSET,
        offsetCharIndex: DEFAULT_CHAR_OFFSET
    },
    codeEditorTracker: {
        focusedStartingPos: {x: 0, y: 0},
        focusLength: 0,
        gridMatrix: gridMatrix,
        initialChange: [{from: 0, insert: ""}],
        deltaTime: DELTA_TIME,
        content: vimPongLevel1JsonContent,
        editorOffsets: {
            editorTop: EDITOR_TOTAL_TOP_OFFSET,
            editorBottom: EDITOR_HEIGHT,
            editorLeft: EDITOR_TOTAL_LEFT_OFFSET,
            editorRight: EDITOR_WIDTH
        },
        offsetLineIndex: DEFAULT_LINE_OFFSET,
        offsetCharIndex: DEFAULT_CHAR_OFFSET
    },
    pong: {
        lives: 3,
        //code mirror state effects for inline css in editor.
        primaryStateEffects: [],
        secondaryStateEffects: [],
        tertiaryStateEffects: [],
        textTasks: []/*vimPongLevel1Json.textTasks*/,
        targetLocation: vimPongLevel1Json.targetLocation,
        balls: vimPongLevel1Json.balls,
        /*balls:
            [// Multiple balls work.
                {
                    location: {x: EDITOR_WIDTH - BALL_RADIUS, y: BALL_RADIUS},
                    color: '#39ff14',
                    dx: -(.25 * CHARACTER_WIDTH),
                    dy: (.125 * LINE_HEIGHT),
                    radius: BALL_RADIUS
                },
                {
                    location: {x: EDITOR_GUTTER_WIDTH + BALL_RADIUS, y: BALL_RADIUS},
                    color: '#39ff14',
                    dx: (.25 * CHARACTER_WIDTH),
                    dy: (.125 * LINE_HEIGHT),
                    radius: BALL_RADIUS
                }],*/
        paddles:
            [
                {
                    location: {x: EDITOR_WIDTH / 2 - DEFAULT_PADDLE_WIDTH / 2, y: EDITOR_HEIGHT - 50},
                    color: 'blue',
                    length: DEFAULT_PADDLE_WIDTH
                }
            ],
    },
    spaceInvaders: {
        spaceInvaders: [{location: {x: 3, y: 4}, ship: {color: 'blue', img: 'hero.png'}}]

    },
    brickBreaker: {
        brickBreaker: {
            0: {
                location: {x: 10, y: 14},
                color: 'gold'
            }
        }
    },
    level: vimPongLevel1Json as Level,
    ui: {
        screen: UserInterfaceScreen.HOMESCREEN,
        modal: UserInterfaceModal.NONE,
        status: UserInterfaceStatus.IDLE,
        cookieConsent: CookieConsentStatus.NULL,
        settingsChanged: false
    },
    auth: {
        signUpForm: {email:'', userName:'', password:'', confirmPassword:'', dateOfBirthCaptcha:''},
        loginForm: {email:'', password:'', dateOfBirthCaptcha:''}
    },
    stripe: {
        clientSecret: ''
    }

}
