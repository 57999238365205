import React from "react";
import { User } from "@auth0/auth0-react";
import './theme/Footer.css';

interface FooterProps {
    user: User | undefined
    isAuthenticated: boolean
    isLoading: boolean
}

export function Footer(props: FooterProps) {
    return (
        <footer className="app-footer">
            <div className="share">
                <a id="twitter" href="https://twitter.com/share" target="_blank" rel={"noreferrer"}>
                    <i className="nes-icon twitter"></i>
                </a>
                <a id="facebook" href="https://www.facebook.com" target="_blank" rel={"noreferrer"}>
                   <i className="nes-icon facebook"></i>
                </a>
                <a id="linkedin" href="https://www.linkedin.com/shareArticle"target="_blank" rel={"noreferrer"}>
                    <i className="nes-icon linkedin"></i>
                </a>
                <a id="github" href="www.github.com" target="_blank" rel={"noreferrer"}>
                    <i className="nes-icon github"></i>
                </a>

            </div>

        </footer>
    )
}

export default Footer
