import styles from './PongBall.module.css';
import { PongBall } from '../../../globalTypes';

export const DrawPongBall = (ball :PongBall, ballRadius :number = 5) => {

  return (
      
    <circle className={styles.pongBall} cx={ball.location.x} cy={ball.location.y} fill={ball.color} r={ball.radius}/>
      
    )

}