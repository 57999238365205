import React from "react";
import { User } from "@auth0/auth0-react";
import {VimUserHeader} from "../user/VimUserHeader";
import {AnonUserHeader} from "../user/AnonUserHeader";
import './theme/SecondaryHeaderPanel.css';
import { v4 } from 'uuid';

interface SecondaryHeaderPanelProps {
    user: User | undefined
    isAuthenticated: boolean
    isLoading: boolean
}

export function SecondaryHeaderPanel(props: SecondaryHeaderPanelProps) {

    return (
        <div className="secondary-header-panel">
            { props.isAuthenticated && !props.isLoading ?
                <VimUserHeader user={props.user}/>
                :
                <AnonUserHeader userId={v4()}/>
            }
        </div>
    )
}

export default SecondaryHeaderPanel