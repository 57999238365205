import {ChangeSpec} from "@codemirror/state";

export enum UserInterfaceScreen {
    HOMESCREEN = 'HOMESCREEN',
    HELPSCREEN = 'HELPSCREEN',
    EDITORSCREEN = 'EDITORSCREEN',
    LOADING = 'LOADINGSCREEN'
}

export enum UserInterfaceStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR' }

export enum CookieConsentStatus {
  NULL = 'NULL',
  ACCEPT = 'ACCEPT',
  DENY = 'DENY' }

export enum LevelStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  RESTART_MODE = 'RESTART_MODE',
  COLLISION = 'COLLISION',
  ACTIVE = 'ACTIVE'
}

export enum TextTaskStatus {
  NONE = 'NONE',
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE'
}

export enum SessionStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  GAME_OVER = 'GAME_OVER',
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE'
}

export enum UserInterfaceModal {
    LOGIN = 'LOGIN',
    SIGNUP = 'SIGNUP',
    STRIPE = 'STRIPE',
    NONE = 'NONE',
}
export enum ApiLoadingState {
  IDLE = 'IDLE',
  ERRORUSEREXISTS = 'ERRORUSEREXISTS',
  UNKNOWNERROR = 'UNKNOWNERROR',
  SERVERERROR = 'SERVERERROR',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS'
}

export interface UserInterface {
  screen: UserInterfaceScreen;
  status: UserInterfaceStatus;
  modal: UserInterfaceModal;
  cookieConsent: CookieConsentStatus;
  settingsChanged: boolean;
}

export interface UserLoginDetails {
    username: string;
    pass: string;
  }
  
  export interface Point {
    x:number, y:number
  }
  export interface PongBall {
    location: Point,
    color: string
    dx: number
    dy: number
    radius: number
  }
  export interface PongPaddle {
    location: Point,
    color: string
    length: number
  }
  export interface PongTracker {
    lives: 3
    primaryStateEffects: GridEffect[]
    secondaryStateEffects: PointAndEffect[]
    tertiaryStateEffects: GridEffect[]
    balls: Array<PongBall>
    textTasks: TextTask[]
    targetLocation: GridLocation
    paddles: Array<PongPaddle>
  }

  export interface Ship {
    color: string
    img: string
  }

  export interface BrickBreakerTracker {

    brickBreaker:{
      [index: number]: {
        location: Point,
        color: string
      }

    }
  } 

  export interface SpaceInvadersTracker {

    spaceInvaders:{
      [index: number]: {
        location: Point,
        ship: Ship
      }

    }
  }

export type CanvasType = 'pong' | 'brickBreaker' | 'spaceInvaders'
export type GameTracker = PongTracker | BrickBreakerTracker | SpaceInvadersTracker

export interface EditorDetailsUpdate {
  textLocations: [Point] 
}

export interface QuestionSubmission {
  questionId: string;
  renderedHtml: string;
  quantity: number;
}

export interface LineContent {
  length: number,
  lines: number
}

export interface GridLocation {
  line: number,
  char: number
}

export type GridLocationChar = GridLocation & {item:string}

export interface PointAndEffect {
  x: number,
  y: number,
  type: string
}

export interface GridEffect {
  locations:GridLocation[]
  type: string
}

export interface GridBlock {
  line: number,
  char: number,
  entry: string,
  source: string
}

export interface EditorTracker {

  focusedStartingPos: { x: number, y: number }
  focusLength: number
  gridMatrix: GridBlock[][]
  initialChange: ChangeSpec[]
  deltaTime: number
  content: string
  editorOffsets: EditorCoords
  offsetLineIndex: number
  offsetCharIndex: number
}

export type RenderEditorTracker = EditorTracker &
    { shouldResetSecondaryFx: boolean,
      styleMatchedText: GridLocationChar[],
      shouldResetStyleMatched: boolean
    }

export interface Rectangle4P {
  topLeft: Point
  topRight: Point
  bottomLeft: Point
  bottomRight: Point
}

export interface Rectangle1P {
  height?: number;
  width?: number;
  x?: number;
  y?: number;
}

export interface EditorCoords {
  editorTop: number
  editorBottom: number
  editorLeft: number
  editorRight: number
}


export interface TextTask {
  identity: number
  startLine: number
  startChar: number
  text: string
  status: TextTaskStatus
}

export interface RenderEditorFeature {
  line: number
  startIndex: number
  endIndex: number
  theme: string
}

export type Level = {
  canvas: CanvasType
  difficulty: string
  timeLimit: number
  levelName: string,
  levelNumber: number
  version4UUID: string
  initialEditorString: string
  renderEditorFeatures: RenderEditorFeature[]
  keystrokesFeatured: string[]
  targetLocation: GridLocation
  textTasks: TextTask[]
}
export type LevelHistory = {
  completed: number[]
  lastAttempted: number
}
export type LevelSummary = {
  levelName: string
  levelIdentity: number,
  levelSummary: string,
  levelInstructions: string[]
}

export type PongLevel = Level & { balls: PongBall[], deltaTime: number }
export type SpaceInvadersLevel = Level & {
  spaceInvaders:{
    [index: number]: {
      location: Point,
      ship: Ship
    }
  }
}
export type BrickBreakerLevel = Level & { bricks: number[] }
export type GameLevel = PongLevel | SpaceInvadersLevel | BrickBreakerLevel

//export type LevelDescription = Pick<Level, 'levelName'>

export type Login = {

    email: string,
    password: string,
    dateOfBirthCaptcha: string,

}

export type LoginErrors = {

    email?: string,
    password?: string,
    dateOfBirthCaptcha?: string,

}

export type SignUp = {

    email: string,
    userName: string,
    password: string,
    confirmPassword: string,
    dateOfBirthCaptcha: string,

}

export type SignUpErrors = {

  email?: string,
  userName?: string,
  password?: string,
  confirmPassword?: string,
  dateOfBirthCaptcha?: string,

}

export type ApiCreateUserAccount = {
    name: string,
    email: string,
    password: string,
}

export type ApiLoginCredentials = {
    email: string,
    password: string,
}

export interface AuthFormInterface {
    signUpForm: SignUp,
    loginForm: Login
}

export interface UserInfoResponse {
    vimUserIdentity: string,
    email: string,
    token: string,
}

export interface VimUser {
  vimUserIdentity: string,
  email: string,
  token: string
}

export interface StripeInterface {
  clientSecret: string
}